import React, { useState, useEffect } from "react";
import {
    LockOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    ProfileOutlined,
    UserOutlined,
} from "@ant-design/icons";
import {
    Layout,
    Button,
    Menu as AntMenu,
    theme,
    notification,
    Dropdown,
    Form,
    Select,
    Menu,
} from "antd";
import { Link, Outlet, useNavigate } from "react-router-dom";
import classes from "./SamplePage.module.scss";
import loadImg from "../../assets/images/MenuIcon/loading-stroke.svg";
import unloadImg from "../../assets/images/MenuIcon/unload.svg";
import agencyImg from "../../assets/images/MenuIcon/Agency.svg";
import vehicleImg from "../../assets/images/MenuIcon/Vehicles.svg";
import hsddetailsImg from "../../assets/images/MenuIcon/HSDdetail.svg";
import excavatorImg from "../../assets/images/MenuIcon/layer1.svg";
import petrolPumpImg from "../../assets/images/MenuIcon/PetrolPump.svg";
import billsImg from "../../assets/images/MenuIcon/Bills.svg";
import clientBillImg from "../../assets/images/MenuIcon/clientBill.svg";
import dashboardImg from "../../assets/images/DashboardIcons/dashboard.svg";
import reportsImg from "../../assets/images/MenuIcon/reportsimg.svg";
import { useLocation } from "react-router-dom";
import AppHelper from "../../helpers/AppHelper";
import { Grid, Button as UIButton } from "@mui/material";
import SubMenu from "antd/es/menu/SubMenu";

export const SamplePage = () => {
    const navigate = useNavigate();
    let currentRoutePath = useLocation().pathname;
    const [thermalProjectSelectForm] = Form.useForm()
    const [globalTpProjectDropDownForm] = Form.useForm();
    const [collapsed, setCollapsed] = useState(false);
    const [isTPProjectModalOpen, setIsTPProjectModelOpen] = useState(true);
    const [thermalPlants, setThermalPlants] = useState([]);
    const [thermalDropDownItems, setThermalDropDownItems] = useState([]);
    const [projectsDropDown, setProjectDropDown] = useState([]);
    const [welcomeUserName, setWelcomeUserName] = useState("");
    const [globalTpDropDownValue, setGlobalTpDropDownValue] = useState("ALL");
    const { Header, Sider, Content } = Layout;
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const sessionUserInfo = AppHelper.decryptText(
        sessionStorage.getItem("userInfo")
    );
    const sessionGlobalValues = AppHelper.decryptText(
        sessionStorage.getItem("globalValues")
    );
    const loadId = sessionUserInfo?.userID ?? "";

    const NavigationButton = ({ to, icon, label, routePathsToMatch, subMenu }) => {
        const isMenuActive = AppHelper.matchStartsWithInArrayByValue(
            routePathsToMatch,
            currentRoutePath
        );

        return subMenu ? (
            <AntMenu.SubMenu
                key={to}
                icon={icon}
                title={label}
                className={isMenuActive === true ? `${classes["menu-active"]}` : ""}
            >
                {subMenu.map((item) => (
                    <NavigationButton key={item.to} {...item} />
                ))}
            </AntMenu.SubMenu>
        ) : (
            <AntMenu.Item
                key={to}
                icon={icon}
                className={isMenuActive === true ? `${classes["menu-active"]}` : ""}
            >
                <Link to={to}>{label}</Link>
            </AntMenu.Item>
        );
    };
    const menuItems = [
        {
            to: "/dashboard",
            icon: (
                <img
                    src={dashboardImg}
                    alt="dashboard"
                    className={classes["menu-icons"]}
                />
            ),
            label: 'Dashboard',
            routePathsToMatch: ["/dashboard", "/dashboard/*"],
        },
        {
            to: "/loadings",
            icon: (
                <img
                    src={loadImg}
                    alt="Loading Data"
                    className={classes["menu-icons"]}
                />
            ),
            label: 'Loading',
            routePathsToMatch: ["/loadings", "/loadings/*"],
        },
        {
            to: "/unloadings",
            icon: (
                <img
                    src={unloadImg}
                    alt="Unloading Data"
                    className={classes["menu-icons"]}
                />
            ),
            label: "Unloading",
            routePathsToMatch: ["/unloadings", "/unloadings/*"],
        },
        {
            to: "/hsd-details",
            icon: (
                <img
                    src={hsddetailsImg}
                    alt="HSD Details"
                    className={classes["menu-icons"]}
                />
            ),
            label: "HSD Details",
            routePathsToMatch: ["/hsd-details", "/hsd-details/*"],
        },
        {
            to: "/excavators",
            icon: (
                <img
                    src={excavatorImg}
                    alt="Excavator"
                    className={classes["menu-icons"]}
                />
            ),
            label: "Excavators",
            routePathsToMatch: ["/excavator", "/excavator/*"],
        },
        {
            to: "/agencies",
            icon: (
                <img src={agencyImg} alt="Agencies" className={classes["menu-icons"]} />
            ),
            label: "Agencies",
            routePathsToMatch: ["/agencies", "/agencies/*"],
        },
        {
            to: "/petrol-pumps",
            icon: (
                <img
                    src={petrolPumpImg}
                    alt="HSD Details"
                    className={classes["menu-icons"]}
                />
            ),
            label: "Petrol Pumps",
            routePathsToMatch: ["/petrol-pumps", "/petrol-pumps/*"],
        },
    ];

    const logout = () => {
        // Clear session storage
        sessionStorage.clear()
        navigate("/");
        notification.success({
            message: "Success",
            description: "User has been loggedout successfully",
        });
    };

    const changePassword = () => {
        navigate(`/changepassword/${loadId}`);
    };

    const profileSettings = () => {
        navigate(`/profilesettings/${loadId}`);
    };

    const dropDownItems = [
        {
            to: "/profilesettings",
            label: "Profile Settings",
            key: "1",
            icon: <ProfileOutlined />,
            onClick: () => profileSettings(),
        },
        {
            to: "/changepassword",
            label: "Change Password",
            key: "2",
            icon: <LockOutlined />,
            onClick: () => changePassword(),
        },
        {
            label: "Logout",
            key: "3",
            icon: <LogoutOutlined />,
            onClick: () => logout(),
        },
    ];

    const loadAllThermalPlants = async (userId) => {
        const loggedInUserDetails = await AppHelper.getUserDetailsById(userId);
        return loggedInUserDetails?.data?.attributes?.Thermal_Plant;
    };

    const loadProjectsByThermalPlants = async (thermalPlantId) => {
        thermalProjectSelectForm.setFieldsValue({
            projectGlobal: null
        })
        setProjectDropDown([]);
        const thermalPlantIdByName = thermalPlants?.data?.find(
            (item) => item?.attributes?.Name === thermalPlantId
        );
        const projectByThermalPlant = await AppHelper.getProjectsByThermalPlants(
            thermalPlantIdByName?.id
        );
        const projectDropDownByTP = AppHelper.setProjectDropDownByThermalPlants(
            projectByThermalPlant
        );
        setProjectDropDown(projectDropDownByTP);
        const globalTpProjectValues = {
            thermalPlantGlobal: thermalPlantId,
            projectGlobal: projectDropDownByTP[0]?.value,
        };
        globalTpProjectDropDownForm.setFieldsValue(globalTpProjectValues);
        setGlobalTpDropDownValue(thermalPlantId);
        if (isTPProjectModalOpen === false)
            setGLobalValuesAndReload(globalTpProjectValues);
    };

    const setGLobalValuesAndReload = (values) => {
        const storageUserInfo = AppHelper.decryptText(sessionStorage.getItem('userInfo'))
        // convert text to id for both thermal plant and project
        const thermalPlantIdByName = thermalPlants?.data?.find(
            (item) => item?.attributes?.Name === values?.thermalPlantGlobal
        );
        const globalThermalPlantProjects = {
            thermalPlantGlobal: thermalPlantIdByName?.id ?? "ALL",
            projectGlobal: values?.projectGlobal ?? "ALL",
        };
        const encryptGlobalValues = AppHelper.encryptText(
            globalThermalPlantProjects
        );
        sessionStorage.clear()
        sessionStorage.setItem("globalValues", encryptGlobalValues);
        sessionStorage.setItem("userInfo", AppHelper.encryptText(storageUserInfo));
        // redirect to loadings page after setting values
        window.location.href = "/dashboard";
    };

    const submitForm = (values) => {
        // set model status in session storage
        setGLobalValuesAndReload(values);
    };

    const updateGlobalValues = async (e) => {
        const storageUserInfo = AppHelper.decryptText(sessionStorage.getItem('userInfo'))
        const globalThermalPlantProjects = {
            thermalPlantGlobal: globalTpDropDownValue,
            projectGlobal: e,
        };
        const encryptGlobalValues = AppHelper.encryptText(
            globalThermalPlantProjects
        );
        sessionStorage.clear()
        sessionStorage.setItem("globalValues", encryptGlobalValues);
        sessionStorage.setItem("userInfo", AppHelper.encryptText(storageUserInfo));
        // redirect to loadings page after setting values
        window.location.href = "/dashboard";
    };

    const getAllProjectsBYThermalPlants = async (thermalPlantId) => {
        const projects = await AppHelper.getProjectsByThermalPlants(thermalPlantId);
        setProjectDropDown(AppHelper.setProjectDropDownByThermalPlants(projects));
    };

    const onClickBillsMenu = (e) => {
        if (e.key === "1") {
            navigate("/vehicles")
        } else if (e.key === "2") {
            navigate("/vehicles/vehicle-report")
        } else if (e.key === "3") {
            navigate("/bills/loading/pending-loading-bills")
        } else if (e.key === "4") {
            navigate("/bills/loading/generated-loading-bills")
        } else if (e.key === "5") {
            navigate("/bills/unloading/pending-unloading-bills")
        } else if (e.key === "6") {
            navigate("/bills/unloading/generated-unloading-bills")
        } else if (e.key === "7") {
            navigate("/bills/transport/pending-transport-bills")
        } else if (e.key === "8") {
            navigate("/bills/transport/generated-transport-bills")
        } else if (e.key === "9") {
            navigate("/bills/client-bill/pending-client-bill")
        } else if (e.key === "10") {
            navigate("/bills/client-bill/generated-client-bill")
        } else if (e.key === "11") {
            navigate("/reports/summary")
        } else if (e.key === "12") {
            navigate("/reports/payment")
        } else if (e.key === "13") {
            navigate("/bills/petrol-pump/pending-petrol-pump-bill")
        } else if (e.key === "14") {
            navigate("/bills/petrol-pump/generated-petrol-pump-bill")
        }
    }

    useEffect(() => {
        // redirect to login if userInfo is expired
        const getUserInfoFromSession = AppHelper.decryptText(
            sessionStorage.getItem("userInfo")
        );
        if (!getUserInfoFromSession) {
            navigate("/");
        }
        setWelcomeUserName(
            getUserInfoFromSession?.userName
                ? getUserInfoFromSession?.userName.toUpperCase()
                : ""
        );
        loadAllThermalPlants(getUserInfoFromSession?.userID).then((response) => {
            setThermalPlants(response);
            setThermalDropDownItems(AppHelper.getThermalsDropDown(response?.data));

            /** Get global thermal plant and project value from session storage if it is available or not
             * If not available, then ask to set global values, otherwise show normal pages
             */
            const globalTpProjectValues = AppHelper.decryptText(
                sessionStorage.getItem("globalValues")
            );
            if (globalTpProjectValues) {
                setGlobalTpDropDownValue(globalTpProjectValues?.thermalPlantGlobal);
                getAllProjectsBYThermalPlants(
                    globalTpProjectValues?.thermalPlantGlobal
                );
                setIsTPProjectModelOpen(false);
                const thermalPlantIdByName = response?.data?.find(
                    (item) => item?.id === globalTpProjectValues?.thermalPlantGlobal
                );
                globalTpProjectDropDownForm.setFieldsValue({
                    thermalPlantGlobal: thermalPlantIdByName?.attributes?.Name,
                    projectGlobal: globalTpProjectValues?.projectGlobal,
                });
            }
        });
    }, []);

    return (
        <>
            <Layout style={{ height: "100vh" }}>
                {sessionGlobalValues.length === 0 ? (
                    <div className={classes["globalTpProjectModal"]}>
                        <div className={classes["globalModalInputFields"]}>
                            <Form layout="vertical" form={thermalProjectSelectForm} onFinish={submitForm}>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="wrap"
                                >
                                    <h2>Please choose Thermal Plant and project to continue</h2>
                                    <div className={classes["globalModalInputStarts"]}>
                                        <Grid item lg={12} md={12} xs={12}>
                                            <Form.Item
                                                name="thermalPlantGlobal"
                                                id="thermalPlantGlobal"
                                                label="Thermal Plant"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select thermal plant",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    id="FirstPageThermalDropDown"
                                                    showSearch
                                                    defaultActiveFirstOption
                                                    options={thermalDropDownItems}
                                                    onChange={loadProjectsByThermalPlants}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="projectGlobal"
                                                id="projectGlobal"
                                                label="Project"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select project",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    defaultActiveFirstOption
                                                    options={projectsDropDown}
                                                    placeholder="Select"
                                                />
                                            </Form.Item>
                                        </Grid>
                                    </div>
                                    <div className={classes["submit-button-area"]}>
                                        <UIButton
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            className={classes["submit-button"]}
                                        >
                                            Submit
                                        </UIButton>
                                    </div>
                                </Grid>
                            </Form>
                        </div>
                    </div>
                ) : (
                    <>
                        <Sider
                            trigger={null}
                            collapsible
                            collapsed={collapsed}
                            style={{ background: colorBgContainer }}
                        >
                            <div className="demo-logo-vertical" />
                            <AntMenu mode="vertical" onClick={onClickBillsMenu}>
                                {menuItems.map((item) => (
                                    <NavigationButton key={item.to} {...item} />
                                ))}
                                <SubMenu title="Vehicles" icon={<img src={vehicleImg} alt="vehicle icon" className={classes["menu-icons"]} />}>
                                    <Menu.Item key="1">Vehicles</Menu.Item>
                                    {/*<Menu.Item key="2">Vehicle Report</Menu.Item>*/}
                                </SubMenu>
                                <SubMenu title="Vendor Bill" icon={<img src={billsImg} alt="Bills icon" className={classes["menu-icons"]} />}>
                                    <SubMenu title="Loading">
                                        <Menu.Item key="3">Pending Loading Bills</Menu.Item>
                                        <Menu.Item key="4">Generated Loading Bills</Menu.Item>
                                    </SubMenu>
                                    <SubMenu title="Unloading">
                                        <Menu.Item key="5">Pending Unloading Bills</Menu.Item>
                                        <Menu.Item key="6">Generated Unloading Bills</Menu.Item>
                                    </SubMenu>
                                    <SubMenu title="Transport">
                                        <Menu.Item key="7">Pending Transport Bills</Menu.Item>
                                        <Menu.Item key="8">Generated Transport Bills</Menu.Item>
                                    </SubMenu>
                                    {/* <SubMenu title="Petrol Pump">
                                        <Menu.Item key="13">Pending Petrol Pump Bills</Menu.Item>
                                        <Menu.Item key="14">Generated Petrol Pump Bills</Menu.Item>
                                    </SubMenu> */}
                                </SubMenu>
                                <SubMenu title="Client Bill" icon={<img src={clientBillImg} alt="client bill icon" className={classes["menu-icons"]} />}>
                                    <Menu.Item key="9">Pending Client Bill</Menu.Item>
                                    <Menu.Item key="10">Generated Client Bill</Menu.Item>
                                </SubMenu>
                                <SubMenu title="Reports" icon={<img src={reportsImg} alt="reports icon" className={classes["menu-icons"]} />}>
                                    <Menu.Item key="11">Summary</Menu.Item>
                                    <Menu.Item key="12">Payment</Menu.Item>
                                </SubMenu>
                            </AntMenu>
                            <div className={classes["user-drop-down-element"]}>
                                <Dropdown.Button
                                    menu={{ items: dropDownItems }}
                                    icon={<UserOutlined />}
                                    className={
                                        collapsed === false
                                            ? classes["profile-drop-down-menus"]
                                            : classes["profile-drop-down-menus-collapsed"]
                                    }
                                >
                                    {welcomeUserName}
                                </Dropdown.Button>
                            </div>
                        </Sider>
                        <Layout>
                            <>
                                <Header className={classes["header-items"]}>
                                    <Button
                                        type="text"
                                        icon={
                                            collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                                        }
                                        onClick={() => setCollapsed(!collapsed)}
                                        style={{
                                            fontSize: "16px",
                                            width: 64,
                                            height: 64,
                                            color: "#fff",
                                        }}
                                    />
                                    <div className={classes["globalHeaderDropDown"]}>
                                        <Form
                                            form={globalTpProjectDropDownForm}
                                        >
                                            <Grid
                                                container
                                                spacing={1}
                                                direction="row"
                                                justify="center"
                                                alignItems="center"
                                                alignContent="center"
                                                wrap="wrap"
                                            >
                                                <Grid item lg={6} md={6} xs={12}>
                                                    <Form.Item
                                                        name="thermalPlantGlobal"
                                                        id="thermalPlantGlobal"
                                                        label={false}
                                                    >
                                                        <Select
                                                            options={thermalDropDownItems}
                                                            placeholder="ALL"
                                                            onChange={loadProjectsByThermalPlants}
                                                            className={classes["globalInput"]}
                                                        />
                                                    </Form.Item>
                                                </Grid>
                                                <Grid item lg={6} md={6} xs={12}>
                                                    <Form.Item
                                                        name="projectGlobal"
                                                        id="projectGlobal"
                                                        label={false}
                                                    >
                                                        <Select
                                                            options={projectsDropDown}
                                                            placeholder="ALL"
                                                            onChange={updateGlobalValues}
                                                            className={classes["globalInput"]}
                                                        />
                                                    </Form.Item>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    </div>
                                </Header>
                                <Content
                                    style={{
                                        margin: "24px 16px",
                                        background: colorBgContainer,
                                        overflowY: "scroll",
                                    }}
                                >
                                    <Outlet />
                                </Content>
                            </>
                        </Layout>
                    </>
                )}
            </Layout>
        </>
    );
};