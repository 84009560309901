import { Typography, Grid, Button as UIButton } from "@mui/material";
import {
    Card,
    DatePicker,
    Drawer,
    Form,
    Input,
    Modal,
    Pagination,
    Select,
    Spin,
    Upload,
    notification,
    Col,
    Row,
    Tooltip,
    Popconfirm,
    Radio
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./HSDDetailsData.module.scss";
import AppHelper from "../../../helpers/AppHelper";
import constantsArr from "../../../constants/global-constants";
import {
    EditOutlined,
    EyeOutlined,
    FilterOutlined,
    ImportOutlined,
    SearchOutlined,
    UploadOutlined,
    LoadingOutlined,
    DeleteOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import importHsdDetailsSampleFile from '../../../assets/import-samples/hsd_detail_import_sample_file.xlsx';
import totalCountImg from "../../../assets/images/HeaderIcons/totalcount.svg"
import totalLitresImg from "../../../assets/images/HeaderIcons/totalLitres.svg"
import totalAmountImg from "../../../assets/images/HeaderIcons/totalamount.svg"
import Search from "antd/es/input/Search";

export const HSDDetailsData = () => {
    const [loading, setLoading] = useState(true);
    const { RangePicker } = DatePicker;
    const params = new URLSearchParams(document.location.search);
    const [hsdDetailsData, setHSDDetailsData] = useState(true);
    const [showPagination, setShowPagination] = useState(false);
    const [currentPagePagination, setCurrentPagePagination] = useState(params.get('currentPage') ?? 1);
    const [currentPage, pageSize] = [params.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE, params.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE];
    const [currentPageSizePagination, setCurrentPageSizePagination] = useState(constantsArr.DEFAULT_PAGINATION_SIZE);
    const [open, setOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [vehicleLists, setVehicleLists] = useState([]);
    const [vehicleDropDown, setVehicleDropDown] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [transportationAgencyDropDown, setTransportationAgencyDropDown] = useState([]);
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
    const [petrolPumpsDropDownValues, setPetrolPumpsDropDownValues] = useState(true)
    const [petrolPumpsValues, setPetrolPumpsValues] = useState(true)
    const [rangePickerStartDateValue, setRangePickerStartDateValue] = useState("")
    const [rangePickerEndDateValue, setRangePickerEndDateValue] = useState("")
    const [filterHSDForm] = Form.useForm()
    const [excelImportErrors, setExcelImportErrors] = useState({})
    const [isModalOpenExcelErrors, setIsModalOpenExcelErrors] = useState(false)
    const [uploadedFile, setUploadedFile] = useState({})
    const maxUploadedImportFileSize = constantsArr.MAX_IMPORT_EXCEL_FILE_SIZE_MB * 1024 * 1024
    const allowedImportFileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
    const [hsdLtrStartFilter, setHSDStartFilter] = useState("")
    const [hsdLtrEndFilter, setHSDEndFilter] = useState("")
    const [sortValue, setSortValue] = useState(params.get('sort') ?? 'updatedAt:desc'); // Default sorting
    const [searchValue, setSearchValue] = useState('');
    const [sortSpinning, setSortSpinning] = useState(false);
    const [hideDeleteIcon, setHideDeleteIcon] = useState(false); // State to manage delete icon visibility
    const [isGeneratedEntriesModalOpen, setGeneratedEntriesIsModalOpen] = useState(false);
    const [modelItem, setModelItem] = useState({});
    const [currentDefaultValueCancelledPendingUnload, setCurrentDefaultValueCancelledPendingUnload] = useState(null)
    const [cancelledCheckBoxFilter, setCancelledCheckBoxFilter] = useState(false)
    const sessionhsdDetailsFilterValues = AppHelper.decryptText(sessionStorage.getItem('hsdDetails_schema_filter_form_values'))
    const sessionhsdDetailsChallanNoFilterValues = AppHelper.decryptText(sessionStorage.getItem('hsdDetails_schema_challan_filter_values'))
    const sessionhsdDetailsSchemaSortOrder = AppHelper.decryptText(sessionStorage.getItem('hsdDetails_schema_sort_order_value'))
    const [startDateFilterFormDateRange, setStartDateFilterFormDateRange] = useState(null)
    const [endDateFilterFormDateRange, setEndDateFilterFormDateRange] = useState(null)
    const dateFormatDayjsConversion = constantsArr.GLOBAL_DATE_FORMAT_DAYJS;

    const props = {
        name: 'file',
        action: false,
        beforeUpload: file => checkBeforeUpload(file),
        maxCount: 1,
        onRemove: () => {
            setUploadedFile({});
        },
        uploadedFile
    };

    const changePageNumber = (currentPage, pageSize) => {
        setCurrentPageSizePagination(pageSize);
        if (currentPage === currentPagePagination && currentPageSizePagination === pageSize) {
            // page number is being changed then go to next page
            currentPage++;
        }
        loadHSDDetails(((sessionhsdDetailsSchemaSortOrder && sessionhsdDetailsSchemaSortOrder !== "") ? sessionhsdDetailsSchemaSortOrder : sortValue), currentPage, pageSize, sessionhsdDetailsFilterValues, sessionhsdDetailsChallanNoFilterValues);
    };

    const showGeneratedEntriesModel = (e, item) => {
        setGeneratedEntriesIsModalOpen(true);
        setModelItem(item)
    }

    const onCancelGeneratedEntriesModel = () => {
        setGeneratedEntriesIsModalOpen(false);
    }

    const handleRangePicker = (e) => {
        if (e) {
            setRangePickerStartDateValue(dayjs(e[0]).format("YYYY-MM-DD") ?? "")
            setRangePickerEndDateValue(dayjs(e[1]).format("YYYY-MM-DD") ?? "")
        } else {
            setRangePickerStartDateValue(null)
            setRangePickerEndDateValue(null)
            setStartDateFilterFormDateRange(null)
            setEndDateFilterFormDateRange(null)
        }
    }

    const handleModelExcelErrorsCancel = () => setIsModalOpenExcelErrors(false)

    const checkBeforeUpload = (file) => {
        const fileType = file?.type ?? ""
        const fileSize = file?.size ?? 0
        setUploadedFile({ ...uploadedFile, file })
        if (!fileType) {
            notification.error({ message: "Error", description: "Invalid file detected" })
            return false
        }

        if (allowedImportFileTypes.includes(fileType)) {
            // check size of uploaded file should not be too big
            if (fileSize > maxUploadedImportFileSize) {
                notification.error({ message: "Error", description: "The uploaded file exceeds the maximum allowable size of 8 MB." })
                return false
            }
        } else {
            // wrong file type detected throw error
            notification.error({ message: "Error", description: "The uploaded file must be in either the xls or xlsx format." })
            return false
        }
        return false
    }

    const filterHSDDetails = async (formValues) => {
        const transportationAgency = agencies.find((item) => item?.attributes?.Company_name === formValues?.Transporter_Agency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
        const vehicleDetailsByNumber = vehicleLists?.data?.find((obj) => obj?.Vehicle_Number === formValues?.Vehicle_Number);
        const petrolPumpById = petrolPumpsValues?.find((obj) => obj?.attributes?.Petrolpump_Name === formValues?.Petrol_Pump_Name);
        formValues.Transporter_Agency = transportationAgency?.id ?? ""
        formValues.Vehicle_Number = vehicleDetailsByNumber?.id ?? ""
        formValues.Petrolpump_Name = petrolPumpById?.id ?? ""
        formValues.Date_of_Loading = `${rangePickerStartDateValue}~${rangePickerEndDateValue}`
        formValues.hsdLTR = `${hsdLtrStartFilter}~${hsdLtrEndFilter}`
        formValues.Cancelled_Pending_T0_Unload = ((!sessionhsdDetailsFilterValues?.Cancelled_Pending_T0_Unload || sessionhsdDetailsFilterValues?.Cancelled_Pending_T0_Unload !== cancelledCheckBoxFilter) ? cancelledCheckBoxFilter : sessionhsdDetailsFilterValues?.Cancelled_Pending_T0_Unload)
        loadHSDDetails(((sessionhsdDetailsSchemaSortOrder && sessionhsdDetailsSchemaSortOrder !== "") ? sessionhsdDetailsSchemaSortOrder : sortValue), currentPage, pageSize, formValues, sessionhsdDetailsChallanNoFilterValues);
        setOpen(false)
        sessionStorage.setItem('hsdDetails_schema_filter_form_values', AppHelper.encryptText(formValues))
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }

    const resetFilterForm = (e) => {
        e.preventDefault()
        filterHSDForm.resetFields()
        setRangePickerStartDateValue("")
        setRangePickerEndDateValue("")
        setStartDateFilterFormDateRange("")
        setEndDateFilterFormDateRange("")
        setHSDStartFilter("")
        setHSDEndFilter("")
        setCancelledCheckBoxFilter(false)
        setCurrentDefaultValueCancelledPendingUnload(null)
        sessionStorage.removeItem("hsdDetails_schema_filter_form_values")
    }

    const loadHSDDetails = async (sortOrder, currentPage, pageSize, filterArgs = [], challanNo = "") => {
        setCurrentPagePagination(currentPage);
        setSortSpinning(true);
        return AppHelper.getHSDDetails(sortOrder, currentPage, pageSize, true, filterArgs, challanNo).then((response) => {
            setSortSpinning(false);
            setLoading(false);
            setShowPagination(true);
            if (response?.error) {
                console.log(response?.error);
            } else {
                setHSDDetailsData(response);
            }
        });
    };

    const handleChange = (e) => {
        if (e.target.name === 'HSD_LTR_start') {
            setHSDStartFilter(Math.abs(e.target.value))
        } else if (e.target.name === 'HSD_LTR_end') {
            setHSDEndFilter(Math.abs(e.target.value))
        } else if (e.target.name === 'Cancelled_Pending_T0_Unload') {
            setCancelledCheckBoxFilter(e.target.value)
            setCurrentDefaultValueCancelledPendingUnload(e.target.value)
        }
    }

    const showDrawer = () => setOpen(true);

    const onClose = () => setOpen(false)

    const showModalPopUp = () => {
        setIsModalOpen(true)
        setHideDeleteIcon(false);
    }

    const handleModelCancel = () => setIsModalOpen(false)

    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const handleUploadFiles = async () => {
        if (Object.keys(uploadedFile).length === 0) {
            notification.error({ message: "Error", description: "Please upload file to import" })
            return false
        }
        // Set loading to true when upload starts
        setLoading(true);
        setHideDeleteIcon(true); // Hide delete icon when upload button is clicked
        const fileType = uploadedFile?.file?.type ?? ""
        const fileSize = uploadedFile?.file?.size ?? 0
        if (!fileType) {
            notification.error({ message: "Error", description: "Invalid file detected" });
            setLoading(false); // Set loading to false when upload finishes
            return false;
        }

        if (allowedImportFileTypes.includes(fileType)) {
            // check size of uploaded file should not be too big
            if (fileSize > maxUploadedImportFileSize) {
                notification.error({ message: "Error", description: "The uploaded file exceeds the maximum allowable size of 8 MB." })
                setLoading(false);
                return false
            }
        } else {
            // wrong file type detected throw error
            notification.error({ message: "Error", description: "The uploaded file must be in either the xls or xlsx format." })
            setLoading(false);
            return false
        }
        // call to an api to import
        const fileImportResponse = await AppHelper.importHSDDetailsExcel(uploadedFile)
        if (fileImportResponse && Object.keys(fileImportResponse).length > 0) {
            setExcelImportErrors(fileImportResponse);
            setIsModalOpenExcelErrors(true);
            setIsModalOpen(false);
            setLoading(false);
        } else {
            // Check if fileImportResponse is undefined or null
            if (fileImportResponse === undefined || fileImportResponse === null || fileImportResponse === "ERR_NETWORK") {
                // Show error message if fileImportResponse is undefined or null
                notification.error({
                    message: "Error",
                    description: "Failed to import data. Please try again later.",
                });
                setLoading(false);
            } else {
                setIsModalOpenExcelErrors(false)
                setIsModalOpen(false)
                loadHSDDetails(((sessionhsdDetailsSchemaSortOrder && sessionhsdDetailsSchemaSortOrder !== "") ? sessionhsdDetailsSchemaSortOrder : sortValue), currentPage, pageSize, sessionhsdDetailsFilterValues, sessionhsdDetailsChallanNoFilterValues);
                setLoading(false);
                notification.success({
                    message: "Success",
                    description: "HSD Details data imported successfully!",
                    duration: 1.5,
                });
            }
        }
    };

    const handleSearch = (value) => {
        setSearchValue(value);
        sessionStorage.setItem('hsdDetails_schema_challan_filter_values', AppHelper.encryptText(value))
        loadHSDDetails(((sessionhsdDetailsSchemaSortOrder && sessionhsdDetailsSchemaSortOrder !== "") ? sessionhsdDetailsSchemaSortOrder : sortValue), 1, pageSize, sessionhsdDetailsFilterValues, AppHelper.decryptText(sessionStorage.getItem('hsdDetails_schema_challan_filter_values')))
    };

    const handleSortChange = (value) => {
        setCurrentPagePagination(1);
        // Update the sorting state
        setSortValue(value);
        setCurrentPagePagination(1);
        sessionStorage.setItem('hsdDetails_schema_sort_order_value', AppHelper.encryptText(value))
        loadHSDDetails(value, 1, pageSize, sessionhsdDetailsFilterValues, sessionhsdDetailsChallanNoFilterValues);
    };

    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    // Function to show a notification when attempting to close modal during uploading
    const showCannotCloseNotification = () => {
        notification.warning({
            message: 'Uploading in Progress',
            description: 'Please wait for the file upload to complete before closing the modal.',
            duration: 3,
        });
    };

    const handleDeleteHSD = async (loadingId) => {
        const response = await AppHelper.deleteHSDDetailsById(loadingId);
        if (response?.error) {
            console.error('Error response:', response); // Log the response for debugging
            notification.error({
                message: 'Error: Failed to delete HSD details',
                description: response?.error?.message || 'Unknown error',
                duration: 3,
            });
        } else {
            setTimeout(() => {
                notification.success({
                    message: 'Success',
                    description: 'HSD details has been deleted successfully.',
                    duration: 3,
                });
            }, 500)
            loadHSDDetails(((sessionhsdDetailsSchemaSortOrder && sessionhsdDetailsSchemaSortOrder !== "") ? sessionhsdDetailsSchemaSortOrder : sortValue), currentPagePagination, pageSize, sessionhsdDetailsFilterValues, sessionhsdDetailsChallanNoFilterValues);
        }
    };

    useEffect(() => {
        document.title = "HSD Details List";
        // Fetch vehicle and agency data
      
        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then((response) => {
            setAgencies(response)
            setTransportationAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Transportation', sessionGlobalValues));

            AppHelper.getVehiclesWithoutPagination().then((responseVehicle) => {
                setVehicleLists(responseVehicle);
                setVehicleDropDown(AppHelper.getVehicleNumberDropDownWithoutPagination(responseVehicle?.data));

                if (sessionhsdDetailsFilterValues && Object.keys(sessionhsdDetailsFilterValues).length > 0) {
                    // remove `Date` key from session object to prevent overlapping
                    let updatedSessionHsdDetailsFilterFormValues = Object.keys(sessionhsdDetailsFilterValues).filter((key) => key !== 'Date_of_Loading').reduce((object, key) => {
                        object[key] = sessionhsdDetailsFilterValues[key];
                        return object
                    }, {})
                    
                    const transportationAgency = response.find((item) => item?.id === sessionhsdDetailsFilterValues?.Transporter_Agency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
                    const vehicleDetailsByName = responseVehicle?.data?.find((obj) => obj?.id === sessionhsdDetailsFilterValues?.Vehicle_Number);
                    
                    updatedSessionHsdDetailsFilterFormValues.Transporter_Agency = transportationAgency?.attributes?.Company_name ?? null
                    updatedSessionHsdDetailsFilterFormValues.Vehicle_Number = vehicleDetailsByName?.Vehicle_Number ?? null
                    setCurrentDefaultValueCancelledPendingUnload(sessionhsdDetailsFilterValues?.Cancelled_Pending_T0_Unload)

                    const getDateOfRangeFilterForm = sessionhsdDetailsFilterValues?.Date_of_Loading
                    const splitGetDateofRangeFilterForm = getDateOfRangeFilterForm.split("~")
                    const startDate = splitGetDateofRangeFilterForm[0]
                    const endDate = splitGetDateofRangeFilterForm[1] ?? ''
                    if (startDate && startDate !== "" && endDate && endDate !== "") {
                        setStartDateFilterFormDateRange(dayjs(dayjs(startDate, dateFormatDayjsConversion)))
                        setRangePickerStartDateValue(dayjs(startDate).format("YYYY-MM-DD"))
                        setEndDateFilterFormDateRange(dayjs(dayjs(endDate, dateFormatDayjsConversion)))
                        setRangePickerEndDateValue(dayjs(endDate).format("YYYY-MM-DD"))
                    }

                    const getHSDRangeFilterForm = sessionhsdDetailsFilterValues.hsdLTR
                    const splitGetHSDRangeFilterForm = getHSDRangeFilterForm.split("~")
                    const startHSD = splitGetHSDRangeFilterForm[0]
                    const endHSD = splitGetHSDRangeFilterForm[1] ?? ''
                    if(startHSD && startHSD !== "" && endHSD && endHSD !== '') {
                        setHSDStartFilter(startHSD)
                        setHSDEndFilter(endHSD)
                    }
                    
                    filterHSDForm.setFieldsValue(updatedSessionHsdDetailsFilterFormValues)
                }

                if (sessionhsdDetailsChallanNoFilterValues) {
                    setSearchValue(sessionhsdDetailsChallanNoFilterValues)
                }
                loadHSDDetails(((sessionhsdDetailsSchemaSortOrder && sessionhsdDetailsSchemaSortOrder !== "") ? sessionhsdDetailsSchemaSortOrder : sortValue), currentPage, pageSize, sessionhsdDetailsFilterValues, sessionhsdDetailsChallanNoFilterValues);
    
            }).catch(err => {
                console.log(err)
            });
           
        }).catch(err => {
            console.log(err);
        });

        AppHelper.getPetrolPumps().then((response) => {
            setPetrolPumpsValues(response);
            setPetrolPumpsDropDownValues(AppHelper.getPetrolPumpsDropDown(response));
        });
    }, []);

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes['header-fixed-hsd-details']}
            >
                <Grid item lg={8} md={6} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                        className="title-un-loading-text"
                    >
                        HSD Details
                    </Typography>
                </Grid>
                <Grid item lg={4} md={6} xs={12} container spacing={2} className={classes["gridmargintop10"]}>
                    <Grid item lg={3} md={3} xs={6} className={classes["form-select-div-search"]}>
                        <Search
                            placeholder="Search By Challan No."
                            className={classes["search-new-entry"]}
                            value={searchValue}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} xs={6} className={classes["form-select-div-select"]}>
                        <Form>
                            <Form.Item label="Sort By">
                                <Select className={classes["select-new-entry"]} defaultValue={((sessionhsdDetailsSchemaSortOrder && sessionhsdDetailsSchemaSortOrder !=="" ) ? sessionhsdDetailsSchemaSortOrder : sortValue ?? 'Challan_Number:desc')} onChange={handleSortChange}>
                                    <Select.Option value="updatedAt:desc" >Updated Date</Select.Option>
                                    <Select.Option value="Date_of_Loading:asc">Date Ascending</Select.Option>
                                    <Select.Option value="Date_of_Loading:desc">Date Descending</Select.Option>
                                    <Select.Option value="Challan_Number:asc">Challan Number Ascending</Select.Option>
                                    <Select.Option value="Challan_Number:desc">Challan Number Descending</Select.Option>
                                    <Select.Option value="HSD_LTR:asc">HSD Ltr. Ascending</Select.Option>
                                    <Select.Option value="HSD_LTR:desc">HSD Ltr. Descending</Select.Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Grid>
                    <Grid item lg={3} md={3} xs={6}>
                        <UIButton
                            type="primary"
                            onClick={showModalPopUp}
                            variant="outlined"
                            startIcon={<ImportOutlined />}
                            className={classes["import-new-entry"]}
                        >
                            Import
                        </UIButton>
                    </Grid>
                    <Grid item lg={3} md={3} xs={6}>
                        <UIButton
                            onClick={showDrawer}
                            variant="outlined"
                            startIcon={<FilterOutlined />}
                            className={classes["filter-new-entry"]}
                        >
                            Filters
                        </UIButton>
                        <Drawer title="Filters" onClose={onClose} open={open}>
                            <Form layout="vertical" form={filterHSDForm} onFinish={filterHSDDetails}>

                                <Form.Item label="Loading Date" name="Date_of_Loading">
                                    <RangePicker style={{ width: "100%" }} onChange={handleRangePicker} defaultValue={[startDateFilterFormDateRange, endDateFilterFormDateRange]} />
                                </Form.Item>
                                <Form.Item label="Vehicle Number" name="Vehicle_Number">
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        style={{ width: "100%" }}
                                        options={vehicleDropDown}
                                        placeholder="Select"
                                    />
                                </Form.Item>
                                <Form.Item label="Owner" name="Owner">
                                    <Input
                                        placeholder="Enter Owner Name"
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item label="Transporting Agency" name="Transporter_Agency">
                                    <Select
                                        options={transportationAgencyDropDown}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        placeholder="Select"
                                    />
                                </Form.Item>
                                <Form.Item label="Petrol Pump" name="Petrol_Pump_Name">
                                    <Select
                                        options={petrolPumpsDropDownValues}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        placeholder="Select"
                                    />
                                </Form.Item>
                                <Row gutter={[24, 24]} wrap={true}>
                                    <Col span={24}>
                                        <Form.Item
                                            messageVariables=""
                                            label="HSD in Ltr"
                                            wrapperCol={{ span: 30 }}
                                            labelCol={{ span: 30 }}
                                            className={classes['ant-form-item']}
                                        >
                                            <Row gutter={[24, 24]} wrap={true}>
                                                <Col span={12}>
                                                    <Input
                                                        name="HSD_LTR_start"
                                                        min={0}
                                                        step={0.01}
                                                        onKeyDown={preventMinus}
                                                        placeholder="From"
                                                        type="number"
                                                        addonBefore={<SearchOutlined />}
                                                        value={hsdLtrStartFilter}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <Input
                                                        name="HSD_LTR_end"
                                                        min={0}
                                                        step={0.01}
                                                        onKeyDown={preventMinus}
                                                        placeholder="To"
                                                        type="number"
                                                        addonBefore={<SearchOutlined />}
                                                        value={hsdLtrEndFilter}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item label="D/O No." name="D_O_No">
                                    <Input placeholder="Enter D/O No" addonBefore={<SearchOutlined />} />
                                </Form.Item>
                                <Row gutter={[24, 24]} wrap={true}>
                                    <Col span={24}>
                                        <Form.Item
                                            messageVariables=""
                                            wrapperCol={{ span: 30 }}
                                            labelCol={{ span: 30 }}
                                            className={classes['ant-form-item']}
                                        >
                                            <Radio.Group name="Cancelled_Pending_T0_Unload" style={{ width: '100%' }} onChange={handleChange} value={currentDefaultValueCancelledPendingUnload}>
                                                <Row gutter={[24, 24]} wrap={true}  >
                                                    <Col span={12}>
                                                        <Radio name="cancalled_challan" value={1}>Cancelled</Radio>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Radio name="pending_to_unload" value={2}>Pending Unload</Radio>
                                                    </Col>
                                                </Row>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className={classes["search-button-area"]}>
                                    <UIButton size="medium" variant="outlined" color="error" onClick={resetFilterForm}>
                                        Reset
                                    </UIButton>
                                    <UIButton
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes["filter-submit-button"]}
                                    >
                                        Apply
                                    </UIButton>
                                </div>
                            </Form>
                        </Drawer>
                    </Grid>
                </Grid >
                <Grid item lg={12} md={12} xs={12} container spacing={1} className={classes["headerMarginTop"]}>
                    <Grid item lg={3} md={2} xs={1}>
                        <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-first"]}`} fontSize={14}>
                            <div className={classes["header-grid-flex"]}>
                                <div><img src={totalCountImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                <div className={classes["divPadding"]}>
                                    <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={hsdDetailsData?.meta?.totalEntries ?? 0}>
                                        {AppHelper.formatNumber(hsdDetailsData?.meta?.totalEntries ?? 0)}
                                    </Typography><br></br>
                                    <Typography variant="body" color="white">
                                        Total Count
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={2} xs={1}>
                        <Tooltip title={hsdDetailsData?.meta?.totalLitres}>
                            <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-second"]}`} fontSize={14}>
                                <div className={classes["header-grid-flex"]}>
                                    <div><img src={totalLitresImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                    <div className={classes["divPadding"]}>
                                        <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={hsdDetailsData?.meta?.totalLitres ?? 0}>
                                            {AppHelper.formatNumber(hsdDetailsData?.meta?.totalLitres ?? 0)}
                                        </Typography><br></br>
                                        <Typography variant="body" color="white">
                                            Total LTR
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item lg={3} md={2} xs={1}>
                        <Tooltip title={hsdDetailsData?.meta?.totalAmount}>
                            <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-third"]}`} fontSize={14}>
                                <div className={classes["header-grid-flex"]}>
                                    <div><img src={totalAmountImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                    <div className={classes["divPadding"]}>
                                        <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={hsdDetailsData?.meta?.totalAmount ?? 0}>
                                            {AppHelper.formatNumber(hsdDetailsData?.meta?.totalAmount ?? 0)}
                                        </Typography><br></br>
                                        <Typography variant="body" color="white">
                                            Total Amount
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid >
            <Grid container spacing={0} className={classes["marginTop20"]}>
                {!hsdDetailsData?.data?.attributes?.results || sortSpinning ? (
                    <Spin size="large" tip="Please Wait ...">
                        <div className={classes["content"]} />
                    </Spin>
                ) : hsdDetailsData?.data?.attributes?.results?.length === 0 ? (
                    // Show message when data is not available
                    <Typography variant="body1">
                        No hsd detail record is available
                    </Typography>
                ) : (hsdDetailsData?.data?.attributes?.results?.map((item) => (
                    <Grid key={item.id} item lg={4} md={6} xs={12}>
                        <Card bordered={true} className={classes["card-box"]}>
                            <div className={classes["AgencyDetails"]}>
                                <Typography component="div" gutterBottom fontWeight="bold">
                                    Challan No:{" "}
                                    {
                                        item?.Loading?.Cancelled === true ?
                                            <span className={classes["cancelled-loading-challan-no"]}>
                                                {item?.Challan_Number ?? "-"}
                                            </span>
                                            :
                                            item?.Challan_Number ?? "-"
                                    }
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Vehicle Number :
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {item?.Vehicle_Number?.Vehicle_Number ?? "-"}
                                    </span>
                                </Typography>
                                <Typography gutterBottom variant="body2">
                                    Date of Loading :
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {formatDate(item?.Date_of_Loading ?? "-")}
                                    </span>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Petrol Pump Agency :
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {item?.Petrolpump_Name?.Petrolpump_Name.length > 15 ? <span title={item?.Petrolpump_Name?.Petrolpump_Name}>{item?.Petrolpump_Name?.Petrolpump_Name.substring(0, 15)}....</span> : item?.Petrolpump_Name?.Petrolpump_Name ?? "-"}
                                    </span>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Transporting Agency :
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {item?.Transporter_Name?.Company_name.length > 15 ? <span title={item?.Transporter_Name?.Company_name}>{item?.Transporter_Name?.Company_name.substring(0, 15)}....</span> : item?.Transporter_Name?.Company_name ?? "-"}
                                    </span>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    HSD Ltr.:
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {item?.HSD_LTR ?? 0} Ltr.
                                    </span>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    D/O No:
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {item?.Loading?.DO_No ?? "-"}
                                    </span>
                                </Typography>
                            </div>
                            <div className={classes["card-button"]}>
                                <ul className={classes["action-items"]}>
                                    <li>
                                        <Link
                                            to={`/hsd-details/${item?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}`}
                                            title="View Agency Details"
                                        >
                                            <EyeOutlined className={classes["card-icons"]} />
                                        </Link>
                                    </li>

                                    {
                                       
                                        <li>
                                            <Link to={`/hsd-details/edit/${item?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}`}>
                                                <EditOutlined
                                                    className={classes["card-icons"]}
                                                    style={{ color: "#000" }}
                                                />
                                            </Link>
                                        </li>
                                            
                                    }
                                    {
                                        (
                                            item?.Loading?.is_client_billed && item?.Loading?.Client_Bill?.id && item?.Loading?.Client_Bill?.id !== "")
                                            || (item?.Loading?.is_loading_billed && item?.Loading?.Loading_Bill?.data?.id !== "")
                                            || (item?.Loading?.is_transporting_billed && item?.Loading?.Transporting_Bill?.data?.id !== "")
                                            || (item?.Loading?.is_unloading_billed && item?.Loading?.Unloading_Bill?.data?.id !== "")
                                            ? (
                                                <li>
                                                    <DeleteOutlined
                                                        title="Delete HSD"
                                                        className={`${classes['card-icons']} ${item?.Cancelled || item?.Unloading || item?.hsd_detail ? classes['disabled-icon'] : ''}`}
                                                        style={{ color: item?.Cancelled || item?.Unloading || item?.hsd_detail ? '#fff' : '#ff0000' }}
                                                        onClick={(e) => showGeneratedEntriesModel(e, item)}
                                                    />
                                                </li>
                                            ) : (
                                                <li>
                                                    <Popconfirm
                                                        title="Delete the Entry"
                                                        description="Are you sure, you want to delete this hsd record?"
                                                        icon={<QuestionCircleOutlined style={{ color: '#ff0000' }} />}
                                                        onConfirm={() => handleDeleteHSD(item?.id)}
                                                    >
                                                        <DeleteOutlined
                                                            title="Delete HSD"
                                                            className={`${classes['card-icons']} ${item?.Cancelled || item?.Unloading || item?.hsd_detail ? classes['disabled-icon'] : ''}`}
                                                            style={{ color: item?.Cancelled || item?.Unloading || item?.hsd_detail ? '#fff' : '#ff0000' }}
                                                        />
                                                    </Popconfirm>
                                                </li>
                                            )}
                                </ul>

                                <div className={
                                    (
                                        item?.Loading?.is_client_billed && item?.Loading?.Client_Bill?.id && item?.Loading?.Client_Bill?.id !== "")
                                        || (item?.Loading?.is_loading_billed && item?.Loading?.Loading_Bill?.id && item?.Loading?.Loading_Bill?.id !== "")
                                        || (item?.Loading?.is_transporting_billed && item?.Loading?.Transporting_Bill?.id && item?.Loading?.Transporting_Bill?.id !== "")
                                        || (item?.Loading?.is_unloading_billed && item?.Loading?.Unloading_Bill?.id && item?.Loading?.Unloading_Bill?.id !== "") ? `${classes["generated-bill-ribbon"]}` : ""
                                }>
                                    <Typography variant="body2">
                                        {
                                            (
                                                item?.Loading?.is_client_billed && item?.Loading?.Client_Bill?.id && item?.Loading?.Client_Bill?.id !== "")
                                                || (item?.Loading?.is_loading_billed && item?.Loading?.Loading_Bill?.id && item?.Loading?.Loading_Bill?.id !== "")
                                                || (item?.Loading?.is_transporting_billed && item?.Loading?.Transporting_Bill?.id && item?.Loading?.Transporting_Bill?.id !== "")
                                                || (item?.Loading?.is_unloading_billed && item?.Loading?.Unloading_Bill?.id && item?.Loading?.Unloading_Bill?.id !== "") ? "Generated" : ""}
                                    </Typography>
                                </div>
                                <>
                                    <Modal open={isGeneratedEntriesModalOpen} onCancel={onCancelGeneratedEntriesModel} centered footer={null} width={700}>
                                        <Grid className={classes["alert-bottom-border"]}>
                                            <Typography variant="h5" color="initial" className={classes["alert-text"]}>Alert !</Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography variant="body1" color="#555555" className={classes["alert-description"]}>Editing this entry is only allowed after it's been removed from the generated bills</Typography>
                                        </Grid>
                                        <Grid
                                            direction="row"
                                            justify="center"
                                            alignItems="center"
                                            alignContent="center"
                                            className={classes["generated-entries-modal-table"]}
                                            container
                                        >

                                            <Grid className={classes["generated-entries-model-heading"]}>
                                                <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="#908f8f" paddingLeft="15px">Bill Type</Typography></Grid>
                                                <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="#908f8f">Agency Name</Typography></Grid>
                                                <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="#908f8f">Bill No.</Typography></Grid>
                                            </Grid>
                                            {
                                                modelItem?.Loading?.is_transporting_billed && modelItem?.Loading?.Transporting_Bill?.id && modelItem?.Loading?.Transporting_Bill?.id !== ""
                                                    ?
                                                    <Grid container spacing={0} className={classes["generated-entries-model-description"]}>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="intial" paddingLeft="15px">Transporting Bill</Typography></Grid>
                                                        <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="intial">{modelItem?.Transporter_Name?.Company_name}</Typography></Grid>
                                                        <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="intial">{modelItem?.Loading?.Transporting_Bill?.bill_number}</Typography></Grid>
                                                    </Grid>
                                                    :
                                                    ""
                                            }
                                            {
                                                modelItem?.Loading?.is_client_billed && modelItem?.Loading?.Client_Bill?.id && modelItem?.Loading?.Client_Bill?.id !== ""
                                                    ?
                                                    <Grid container spacing={0} className={classes["generated-entries-model-description"]}>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="intial" paddingLeft="15px">Client Bill</Typography></Grid>
                                                        <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="intial">-</Typography></Grid>
                                                        <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="intial">{modelItem?.Loading?.Client_Bill?.bill_no}</Typography></Grid>
                                                    </Grid>
                                                    :
                                                    ""
                                            }
                                        </Grid>
                                    </Modal>
                                </>
                            </div>
                        </Card>
                    </Grid>
                ))
                )}
            </Grid>
            {showPagination === true && hsdDetailsData?.data?.attributes?.results?.length > 0 ? (
                <div className={classes["pagination-box"]}>
                    <Pagination
                        current={currentPagePagination}
                        defaultCurrent={currentPage}
                        defaultPageSize={currentPageSizePagination}
                        showTotal={(total) => `Total ${total} items`}
                        responsive
                        pageSize={currentPageSizePagination}
                        onChange={changePageNumber}
                        showSizeChanger={false}
                        total={hsdDetailsData?.data?.attributes?.pagination?.total}
                    />
                </div>
            ) : (
                ""
            )}
            <Modal
                centered
                open={isModalOpen}
                onCancel={loading ? showCannotCloseNotification : handleModelCancel}
                footer={(_) => (
                    <>
                        <UIButton
                            onClick={loading ? showCannotCloseNotification : handleModelCancel}
                            variant="outlined"
                            color="error"
                            className={classes['model-buttons']}
                        >
                            Cancel
                        </UIButton>
                        <UIButton
                            onClick={handleUploadFiles}
                            variant="contained"
                            className={classes["add-new-filter-entry"]}
                            disabled={loading} // Disable the Upload button while loading
                        >
                            {loading ? <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} />} /> : 'Upload'} {/* Show spinner while loading */}
                        </UIButton>
                    </>
                )}
            >

                <Upload {...props}>
                    <UIButton
                        startIcon={<UploadOutlined />}
                        className={classes['upload-file-entry']}
                        variant="outlined"
                    >
                        Browse File
                    </UIButton>
                </Upload>
                <h4>Please choose file .xls and .xlsx only. <a href={importHsdDetailsSampleFile} download={true}>Click here</a> to download the sample file.</h4>
                <style>{`
         & .ant-upload-list-item-actions .ant-btn {
          display: ${hideDeleteIcon ? 'none' : 'inline-block'};
        }
      `}</style>
            </Modal>
            {/**
       * Modal popup for excel import errors display
       */}
            {
                excelImportErrors && Object.keys(excelImportErrors).length > 0
                    ?
                    <Modal
                        centered
                        open={isModalOpenExcelErrors}
                        onCancel={handleModelExcelErrorsCancel}
                        width={'60%'}
                        className={classes['error-import-modal']}
                        footer=""
                    >
                        <>
                            <h3 className={classes["error-import-modal-heading"]}>Please find errors in HSD Details import excel data as below:</h3>
                            <div className={classes['error-import-excel-display-data']}>
                                <table border={0} cellPadding={0} cellSpacing={0} width={'100%'}>
                                    <thead>
                                        <tr>
                                            <th width={'8%'} align="center">Row No</th>
                                            <th width={'92%'}>Errors</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.entries(excelImportErrors).map(([key, value]) => {
                                                return <tr key={key}>
                                                    <td align="center"><strong>{key}</strong></td>
                                                    <td>
                                                        <table border={0} cellPadding={0} cellSpacing={0} key={key} width={'100%'}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <ul>
                                                                            {
                                                                                value?.map((item, key) => {
                                                                                    return <li key={key}>
                                                                                        <p>{item}</p>
                                                                                    </li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    </Modal >
                    :
                    ""
            }
        </>
    );
};