import React, { useEffect, useState } from 'react'
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons'
import { Grid, Typography, Button as UIButton } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import AppHelper from '../../../../helpers/AppHelper'
import { DatePicker, Form, Input, Modal, Select, Skeleton, Spin, Upload, notification } from 'antd'
import classes from '../EditExcavator/EditExcavator.module.scss'
import dayjs from 'dayjs'
import constantsArr from '../../../../constants/agency-constant'
import constants from '../../../../constants/global-constants'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import moment from 'moment'

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const EditExcavator = () => {
    const navigate = useNavigate()
    const params = useParams();
    const excavatorId = params?.id ?? ""
    const [hsdForm] = Form.useForm()
    const paramsQuery = new URLSearchParams(document.location.search);
    const pageNo = paramsQuery.get('currentPage') ?? constants.PAGINATION_CURRENT_PAGE
    const pageSize = paramsQuery.get('pageSize') ?? constants.DEFAULT_PAGINATION_SIZE
    const [skeleteonLoading, setSekeletonLoading] = useState(true)
    const [spinLoading, setSpinLoading] = useState(false)
    const [petrolPumpsDropDownValues, setPetrolPumpsDropDownValues] = useState([])
    const [petrolPumpsValues, setPetrolPumpsValues] = useState([])
    const [agencyDataDown, setagencyDataDown] = useState([]);
    const [loadingAgencyLists, setloadingAgencyLists] = useState([]);
    const [formInputFields, setFormInputFields] = useState({
        Vehicle_Number: "",
        Agency_Type: "",
        Date: "",
        Petrol_Pumps: [],
        Owner: "",
        Rate: "0",
        DO_No: "",
        HSD_LTR: "0",
        Remarks: "",
        HSD_AMOUNT: "",
        agency_id: "",
        Project: ""
    })
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
    const sessionUserInfo = AppHelper.decryptText(sessionStorage.getItem("userInfo"));
    const dateFormat = "DD-MM-YYYY";
    const currentDate = dayjs().format("YYYY-MM-DD")
    const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const maxNoOfUploadsAllowed = constants.MAX_UPLOAD_IMAGES
    const allowedMimeTypes = constants.ALLOWED_MIME_TYPES
    const [viewPdf, setViewPdf] = useState(null);
    const maxUploadSizeAllowed = constants.MAX_UPLOAD_SIZE_MB * 1024 * 1024
    const API_URL = process.env.REACT_APP_API_DOMAIN || "";
    const [uploadedFileList, setUploadedFileList] = useState([]);
    const [isLoadingBilled, setIsLoadingBilled] = useState(true);
    const [isUnloadingBilled, setIsUnloadingBilled] = useState(true);



    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

    const transform = (slot) => ({
        ...slot,
        // These slots will be empty
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        CurrentPageInput: () => <></>,
        NumberOfPages: () => <></>,
        MoreActionsPopover: () => <></>,
        GoToFirstPage: () => <></>,
        GoToFirstPageMenuItem: () => <></>,
        GoToNextPage: () => <></>,
        GoToNextPageMenuItem: () => <></>,
        GoToPreviousPage: () => <></>,
        GoToPreviousPageMenuItem: () => <></>,
        GoToLastPage: () => <></>,
        GoToLastPageMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        ShowProperties: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
    });


    const backToHsdDetailsList = (e) => {
        e.preventDefault();
        navigate(`/excavators?currentPage=${pageNo}&pageSize=${pageSize}`)
    }

    const setFormDefaultValues = (values) => {
        console.log("valuesssssssssss", values)
        // get global set project and pass in api
        setIsLoadingBilled(values?.data?.attributes?.is_loading_billed)
        setIsUnloadingBilled(values?.data?.attributes?.is_unloading_billed)
        
        const globalValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
        const defaultValues = {
            Vehicle_Number: values?.data?.attributes?.Vehicle_Number,
            Owner: values?.data?.attributes?.Owner ?? "",
            Date: dayjs(values?.data?.attributes?.Date),
            Agency_Type: values?.data?.attributes?.Agency_Type ?? "",
            agency_id: values?.data?.attributes?.agency_id?.data?.attributes?.Company_name ?? "",
            Petrol_Pumps: values?.data?.attributes?.Petrol_Pumps?.data[0]?.attributes?.Petrolpump_Name ?? "",
            DO_No: values?.data?.attributes?.DO_No ?? "",
            Rate: values?.data?.attributes?.Rate ?? "0",
            HSD_LTR: values?.data?.attributes?.HSD_LTR ?? "0",
            HSD_AMOUNT: values?.data?.attributes?.HSD_AMOUNT ?? "0",
            Remarks: values?.data?.attributes?.Remarks ?? "",
        }
        hsdForm.setFieldsValue(defaultValues)
        setFormInputFields({ ...defaultValues, "Project": globalValues?.projectGlobal })
    }

    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const hsdExcavatorDetails = (e) => {
        const checkPetrolPumpIsInteger = AppHelper.checkIsInteger(e)
        let petrolPumpObject = petrolPumpsValues.find((ele) => checkPetrolPumpIsInteger ? ele?.id === e : ele?.attributes?.Petrolpump_Name === e)
        const selectedDate = dayjs(hsdForm.getFieldValue("Date")).format("YYYY-MM-DD");
        if (!selectedDate) {
            notification.error({
                message: "Error",
                description: "Please select date first to fetch the petrol pump rate",
                duration: 3,
            });
            hsdForm.setFieldsValue({
                Petrol_Pumps: null
            })
            return false
        }

        const hsdDetailsById = petrolPumpsValues.find((obj) => obj.id === petrolPumpObject?.id);
        const petrolPumpRateArrayObject = hsdDetailsById?.attributes?.Rate;
        const sortPetrolPumpRates = AppHelper.makePetrolPumpRatesDescendingOrder(petrolPumpRateArrayObject)

        // find rate by petrol pump and date
        const petrolPumpRate = AppHelper.findPetrolPumpRatebyDateAndPetrolPumpValue(selectedDate, sortPetrolPumpRates)
        hsdForm.setFieldsValue({
            Rate: petrolPumpRate,
            HSD_AMOUNT: ((parseFloat(formInputFields.HSD_LTR) * parseFloat(petrolPumpRate)).toFixed(2)).toString(),
            Petrol_Pumps: hsdDetailsById?.attributes?.Petrolpump_Name
        })
        setFormInputFields({ ...formInputFields, 'Rate': petrolPumpRate })
        setFormInputFields({ ...formInputFields, 'Petrol_Pumps': hsdDetailsById?.id })
    };

    const populateAgencyDropDown = (e) => {
        hsdForm.setFieldsValue({
            agency_id: null,
        })
        setagencyDataDown(AppHelper.getAgencyDropDown(loadingAgencyLists, e, sessionGlobalValues));
    }

    const submitForm = async (formValues) => {
        setIsSubmittingResponse(true);
        let filesJson = JSON.stringify(uploadedFileList)
        const uploadImageResponse = await AppHelper.savePictureData(filesJson)
        const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
        const petrolPumpIdByValue = petrolPumpsValues.find((item) => { return item?.attributes?.Petrolpump_Name === formValues?.Petrol_Pumps })
        formValues['Project'] = globalTpProjectValues.projectGlobal
        formValues['Petrol_Pumps'] = [petrolPumpIdByValue?.id]
        formValues['Date'] = dayjs(formValues.Date).format("YYYY-MM-DD")
        const agency = loadingAgencyLists.find((item) => item?.attributes?.Company_name === formValues?.agency_id && item?.attributes?.Project?.data?.id === globalTpProjectValues.projectGlobal)
        formValues['agency_id'] = agency?.id ?? null
        formValues['Updated_By'] = sessionUserInfo?.userID ?? null
        formValues['Uploaded_Pictures'] = { 'id': uploadImageResponse?.data?.id ?? "" }
        setSpinLoading(true)
        AppHelper.updateExcavator(formValues, excavatorId).then(response => {
            setSpinLoading(false)
            if (response?.error) {
                notification.error({
                    message: "Error",
                    description: `${response?.error.message}`,
                    duration: 3,
                });
            } else {
                notification.success({
                    message: "Success",
                    description: "Excavator details updated successfully.",
                    duration: 3,
                });
                navigate(`/excavators?sort=updatedAt:desc`)
            }
        }).catch(error => {
            setSpinLoading(false)
            notification.error({
                message: "Error",
                description: `Error: ${error.status} - ${error?.error.message}`,
                duration: 3,
            });
        })
        setIsSubmittingResponse(false);
    }

    const customRequest = async ({ file, onSuccess, onError }) => {
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            return false
        }
        // check for maximum size can upload
        let uploadedSize = file?.size ?? 0
        uploadedSize = parseInt(uploadedSize)
        if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
            return false
        }
        const response = await AppHelper.uploadImages(file);
        if (response?.error) {
            onError(new Error("Upload failed"));
        } else {
            onSuccess();
            let imageResponse = response[0]
            Object.entries(imageResponse).forEach(([key, value]) => {
                if (key === 'url') {
                    imageResponse[key] = API_URL + value
                    imageResponse['uid'] = file?.uid
                }
            })
            uploadedFileList.push({ ...imageResponse });
        }
    };

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        console.log(file, 'file')
        if (file.type === 'application/pdf') {
            // Only set viewPdf if the file is a c/PDF
            setViewPdf(file.url || file.preview);
            setPreviewOpen(true);
            if (!viewPdf) {
                setPreviewImage(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        } else {
            setPreviewImage(file.url || file.preview);
            setPreviewOpen(true);
            if (!previewImage) {
                setViewPdf(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        }
    };

    const beforeUpload = (file) => {
        // check correct file type
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            notification.error({
                message: "Error",
                description: "The uploaded file is invalid; only files with the following extensions are accepted: jpg, jpeg, svg, bmp, and png.",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
        // check for maximum size can upload
        let uploadedSize = file?.size ?? 0
        uploadedSize = parseInt(uploadedSize)
        if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
            notification.error({
                message: "Error",
                description: "The uploaded file exceeds the maximum allowed image size; please ensure that the file size does not exceed 2 MB",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
    }

    const handleChange = (e) => {
        setIsSubmittingResponse(false);
        // calculate rate on basis of petrol pump drop down value and set to form input field values
        if (e.target.id === 'HSD_LTR') {
            const hsdLTRVal = ((!e.currentTarget.value || e.currentTarget.value === "") ? 0 : e.currentTarget.value)
            const HSD_AMOUNT = ((parseFloat(hsdLTRVal) * parseFloat(formInputFields.Rate)).toFixed(2)).toString()
            hsdForm.setFieldsValue({
                HSD_AMOUNT: HSD_AMOUNT,
            })
            setFormInputFields({ ...formInputFields, 'HSD_LTR': e.target.value, 'HSD_AMOUNT': HSD_AMOUNT })
        } else if (e.target.id === 'Date') {
            setFormInputFields({ ...formInputFields, [e.target.id]: dayjs(e.target.value).format("YYYY-MM-DD") })
        } else {
            setFormInputFields({ ...formInputFields, [e.target.id]: e.target.value })
        }
    }

    const disableDates = (currentDate) => {
        return currentDate > moment().endOf('day')
    }

    const handleChangeDate = (e) => {
        hsdForm.setFieldsValue({
            Petrol_Pumps: null,
            Rate: null
        })
        setPetrolPumpsDropDownValues([]);
        setPetrolPumpsDropDownValues(AppHelper.getPetrolPumpNameDropDown(petrolPumpsValues));
    }

    const handleChangePictures = (values) => {
        setIsSubmittingResponse(false);
        const fileStatus = values?.file?.status
        let fileList = values?.fileList
        if (fileStatus !== 'removed') {
            fileList = values?.fileList
            const uploadefFileType = values?.file?.type ?? ""
            if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
                return false || Upload.LIST_IGNORE
            } else {
                // check for maximum size can upload
                let uploadedSize = values?.file?.size ?? 0
                uploadedSize = parseInt(uploadedSize)
                if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
                    return false || Upload.LIST_IGNORE
                } else {
                    if (values?.file?.type === 'application/pdf') {
                        const getUploadedFile = values?.fileList.find((item) => item?.uid === values?.file?.uid)
                        let fileReader = new FileReader();
                        // Onload of file read the file content
                        fileReader.onload = function (fileLoadedEvent) {
                            // file = fileLoadedEvent.target.result;
                            // Print data in console
                            getUploadedFile.thumbUrl = fileLoadedEvent.target.result
                        };
                        // Convert data to base64
                        fileReader.readAsDataURL(values?.file?.originFileObj);
                    }
                }
            }
            if (values?.file?.type === 'application/pdf' && values?.file?.status !== 'done') {
                const getUploadedFile = values?.fileList.find((item) => item?.uid === values?.file?.uid)
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                    getUploadedFile.thumbUrl = URL.createObjectURL(e.target.result)
                }
            }
        } else {
            // remove from uploaded file array
            const uploadedFileListUpdated = uploadedFileList.filter((item) => item?.uid !== values?.file?.uid)
            setUploadedFileList(uploadedFileListUpdated)
            setFileList(fileList)
        }
        setFileList(values?.fileList);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    useEffect(() => {
        document.title = "Edit Excavator Details"
        AppHelper.getPetrolPumps().then(response => {
            setPetrolPumpsValues(response)
            setPetrolPumpsDropDownValues(AppHelper.getPetrolPumpsDropDown(response))
        })

        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then((response) => {
            setloadingAgencyLists(response);
            const agencies = response;
            <>
                {

                    excavatorId ?
                        AppHelper.getExcavatorDetailsById(excavatorId).then(response => {
                            let uploadedPictures = response?.data?.attributes?.Uploaded_Pictures?.data?.attributes?.Pictures ?? "";
                            if (uploadedPictures) {
                                uploadedPictures = JSON.parse(uploadedPictures)
                            }
                            let updatedPictures = uploadedPictures.length > 0 ? uploadedPictures.map((image) => ({
                                ...image,
                                url: image?.url,
                                uid: image?.id,
                                name: image?.name,
                                type: image?.mime,
                                status: "done",
                            })) : [];
                            setFileList(updatedPictures);
                            setUploadedFileList(updatedPictures)
                            setSekeletonLoading(false)
                            setagencyDataDown(AppHelper.getAgencyDropDown(agencies, response?.data?.attributes?.Agency_Type, sessionGlobalValues))
                            setFormDefaultValues(response)
                        })
                        :
                        ""
                }
            </>
        });

    }, [])

    return (
        <>
            <Skeleton active loading={skeleteonLoading}>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["edit-excavator-padding"]}
                >
                    <Grid item lg={10} md={9} xs={12}>
                        <Typography variant="h1" fontSize="1.5rem" fontWeight="bold" className="title-un-loading-text">
                            Edit Excavator Details
                        </Typography>
                    </Grid>

                    <Grid item lg={2} md={3} xs={12} container className={classes['action-button']}>
                        <UIButton size="small" variant="contained" onClick={backToHsdDetailsList} className={classes['add-new-entry']}>
                            <ArrowLeftOutlined />&nbsp;Excavators
                        </UIButton>
                    </Grid>
                </Grid>
                <Spin spinning={spinLoading}>
                    <div className={classes['agency-details-margin']}>
                        <Form layout='vertical' form={hsdForm} onFinish={submitForm}>
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                alignContent="center"
                                wrap="wrap"
                            >
                                <Grid item lg={6} md={6} sm={12}>
                                    <Form.Item
                                        name="Vehicle_Number"
                                        label="Vehicle No."
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                    >
                                        <Input id='Vehicle_Number' readOnly onChange={handleChange} disabled className={classes["disabled-input"]} />
                                    </Form.Item>
                                    <Form.Item
                                        name="Agency_Type"
                                        label="Type of Agency"
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                        rules={
                                            [
                                                {
                                                    required: true,
                                                    message: "Please select Type of agency"
                                                }
                                            ]
                                        }
                                    >
                                        <Select
                                            showSearch
                                            id='Agency_Type'
                                            optionFilterProp="children"
                                            filterOption={filterOption}
                                            options={constantsArr.AGENCY_TYPES}
                                            placeholder="Select"
                                            onChange={(e) => populateAgencyDropDown(e)}
                                            disabled={isLoadingBilled || isUnloadingBilled}
                                            className={(isLoadingBilled || isUnloadingBilled) ? classes['disabled-input'] : ""}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="agency_id"
                                        label="Agency Name"
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                        rules={[{
                                            required: true,
                                            message: "Please enter the Agency name."
                                        }]}
                                    >
                                        <Select
                                            showSearch
                                            id='agency_id'
                                            optionFilterProp="children"
                                            filterOption={filterOption}
                                            options={agencyDataDown}
                                            placeholder="Select"
                                            disabled={isLoadingBilled || isUnloadingBilled}
                                            className={(isLoadingBilled || isUnloadingBilled) ? classes['disabled-input'] : ""}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="DO_No"
                                        label="D/O No."
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                        rules={
                                            [
                                                {
                                                    required: true,
                                                    message: "Please enter D/O No."
                                                }
                                            ]
                                        }
                                    >
                                        <Input id='DO_No' onChange={handleChange} 
                                            disabled={isLoadingBilled || isUnloadingBilled}
                                            className={(isLoadingBilled || isUnloadingBilled) ? classes['disabled-input'] : ""} 
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="HSD_LTR"
                                        label="HSD LTR"
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the HSD Ltr.",
                                            }
                                        ]}
                                    >
                                        <Input type="number" id='HSD_LTR' onChange={handleChange}  
                                            disabled={isLoadingBilled || isUnloadingBilled}
                                            className={(isLoadingBilled || isUnloadingBilled) ? classes['disabled-input'] : ""} 
                                        />
                                    </Form.Item>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12}>
                                    <Form.Item
                                        name="Owner"
                                        label="Owner"
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                    >
                                        <Input id='Owner' readOnly onChange={handleChange} disabled className={classes["disabled-input"]} />
                                    </Form.Item>
                                    <Form.Item
                                        name="Date"
                                        label="Date"
                                        rules={[{ required: true, message: "Please select the date" }]}
                                    >
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            picker="date"
                                            id='Date'
                                            maxDate={dayjs(currentDate, "YYYY-MM-DD")}
                                            onChange={handleChangeDate}
                                            format={dateFormat}
                                            disabledDate={disableDates}
                                            disabled={isLoadingBilled || isUnloadingBilled}
                                            className={(isLoadingBilled || isUnloadingBilled) ? classes['disabled-input'] : ""}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="Petrol_Pumps"
                                        label="Petrol Pump Name"
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select petrol pump"
                                            }
                                        ]}
                                    >
                                        <Select
                                            options={petrolPumpsDropDownValues}
                                            placeholder="Please Select"
                                            id='Petrol_Pumps'
                                            onChange={(e) => hsdExcavatorDetails(e)}
                                            disabled={isLoadingBilled || isUnloadingBilled}
                                            className={(isLoadingBilled || isUnloadingBilled) ? classes['disabled-input'] : ""}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="Rate"
                                        label="Rate"
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                    >
                                        <Input type='number' id='Rate' readOnly onChange={handleChange} disabled className={classes["disabled-input"]} />
                                    </Form.Item>
                                    <Form.Item
                                        name='HSD_AMOUNT'
                                        label="HSD Amount"
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                    >
                                        <Input id='HSD_AMOUNT' readOnly disabled className={classes["disabled-input"]} />
                                    </Form.Item>
                                </Grid>
                            </Grid>
                            <Grid item>
                                    <Form.Item
                                        name="Remarks"
                                        label="Remarks"
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                    >
                                        <Input id='Remarks' onChange={handleChange} style={{width: "50%"}} />
                                    </Form.Item>
                                </Grid>
                                
                                <Grid item>
                                <Upload
                                    customRequest={customRequest}
                                    multiple
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChangePictures}
                                    name="Unloading_Images"
                                    withCredentials={true}
                                    beforeUpload={beforeUpload}
                                    accept="image/png, image/jpeg, image/svg+xml, image/bmp, image/x-png, application/pdf"
                                >
                                {fileList.length >= maxNoOfUploadsAllowed ? null : uploadButton}
                            </Upload>
                            <Modal
                                open={previewOpen}
                                title={previewTitle}
                                footer={null}
                                width={800}
                                styles={{ body: { height: '500px', overflowY: 'scroll', maxHeight: '500px' } }}
                                onCancel={handleCancel}
                            >
                                <div
                                    className="rpv-core__viewer"
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.3)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    {viewPdf && (
                                        <>
                                            <div
                                                style={{
                                                    alignItems: 'center',
                                                    backgroundColor: '#eeeeee',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                    display: 'flex',
                                                    padding: '0.25rem',
                                                }}
                                            >
                                                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                                            </div>
                                            <>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.js`}>
                                                        <Viewer fileUrl={viewPdf} plugins={[toolbarPluginInstance]} />
                                                    </Worker>
                                                </div>
                                            </>
                                        </>
                                    )}
                                    {previewImage && ( // Render image if not viewing PDF
                                        <img
                                            alt="example"
                                            style={{
                                                width: "100%",
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </div>
                            </Modal>
                                </Grid>
                            <div className={classes['submit-button-area']}>
                                <UIButton variant="contained" color="primary" type='submit' className={classes['submit-button']} disabled={isSubmittingResponse}>
                                    Save
                                </UIButton>
                                <UIButton variant="outlined" color="error" onClick={backToHsdDetailsList}>
                                    Cancel
                                </UIButton>
                            </div>
                        </Form>
                    </div>
                </Spin>
            </Skeleton>
        </>
    )
}