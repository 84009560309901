import React, { useEffect, useState } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import { ArrowLeftOutlined } from "@ant-design/icons";
import classes from "./CreateVehicleData.module.scss";
import { Button, Checkbox, Form, Input, Modal, notification } from "antd";
import AppHelper from "../../../../helpers/AppHelper";
import { useNavigate } from "react-router-dom";
import constantsArr from "../../../../constants/global-constants";

export const CreateVehicleData = () => {
    const navigate = useNavigate();
    const [createVehicleForm] = Form.useForm()
    const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [vehicleFetchInfo, setVehicleFetchInfo] = useState({})
    const [vehicleNoValue, setVehicleNoValue] = useState("")
    const [isForcefullyValidated, setIsForceFullyValidated] = useState(false)
    const [validatedThroughAPI, setValidatedThroughAPI] = useState(false)
    const [vehicleNumberValid, setVehicleNumberValid] = useState(false); // State for vehicle number validation
    const [isModalOpenInvalidVehicle, setIsModalOpenInvalidVehicle] = useState(false)
    const [isModalOpenNonHGVVehicle, setIsModalOpenNonHGVVehicle] = useState(false)

    const onFinish = async (values) => {
        setIsSubmittingResponse(true);
        values.Vehicle_Information = vehicleFetchInfo ?? {}
        values.Forcefully_Validated = isForcefullyValidated ?? false
        values.Validated_Through_API = validatedThroughAPI ?? false
        
        const vehicle = await AppHelper.postVehicle(values);

        // Optionally, you can show a success message
        if (vehicle?.error) {
            notification.error({
                message: "Error",
                description: vehicle?.error?.message,
                duration: 3,
            });
        } else {
            notification.success({
                message: "Success",
                description: "Vehicle created successfully.",
                duration: 3
            });
            navigate("/vehicles");
        }
        setIsSubmittingResponse(false);
    };

    const backToVehicleLists = (e) => {
        e.preventDefault();
        navigate("/vehicles");
    };

    const onChangeCheckbox = (e) => {
        setIsForceFullyValidated(e.target.checked)
        setIsSubmittingResponse(e.target.checked)
    }

    const handleCancelInvalidVehicle = () => setIsModalOpenInvalidVehicle(false)

    const handleCancelNonHGVVehicle = () => setIsModalOpenNonHGVVehicle(false)

    const validateVehicleNumber = async () => {
        if (!vehicleNoValue || vehicleNoValue === '') {
            notification.error({
                message: "Error",
                description: "Please enter vehicle number",
                duration: 3,
            });
            return false;
        }

        const getVehicleInformation = await AppHelper.validateVehicleNo(vehicleNoValue);
        if (getVehicleInformation?.result?.vehicleCategory && getVehicleInformation?.result?.vehicleCategory !== "HGV") {
            setIsModalOpenInvalidVehicle(true);
            setIsSubmittingResponse(false);
            createVehicleForm.setFieldsValue({
                Owner: "",
                bodyType: "",
                Gross_Weight: "",
                Vehicle_Category: "",
            });
            setVehicleNumberValid(false); // Mark as valid
        } else if (getVehicleInformation?.error?.status === 409) {
            setIsModalOpenNonHGVVehicle(true);
            setIsSubmittingResponse(false);
            createVehicleForm.setFieldsValue({
                Owner: "",
                bodyType: "",
                Gross_Weight: "",
                Vehicle_Category: "",
            });
            setVehicleNumberValid(false); // Mark as invalid
        } else if (getVehicleInformation?.error) {
            notification.error({
                message: "Error",
                description: getVehicleInformation?.error?.message,
                duration: 3,
            });
            createVehicleForm.setFieldsValue({
                Owner: "",
                bodyType: "",
                Gross_Weight: "",
                Vehicle_Category: "",
            });
            setIsSubmittingResponse(false);
        } else {
            // Always populate the form fields with the fetched vehicle information
            const vehicleData = getVehicleInformation?.result ?? getVehicleInformation ?? {};

            // Set the fetched vehicle information in the form fields
            createVehicleForm.setFieldsValue({
                Owner: vehicleData?.owner ?? "",
                Body_Type: vehicleData?.bodyType ?? "",
                Gross_Weight: vehicleData?.grossVehicleWeight ?? "",
                Vehicle_Category: vehicleData?.vehicleCategory ?? "",
            });

            // Update the state to store the fetched vehicle information
            setVehicleFetchInfo(vehicleData);
            setValidatedThroughAPI(true);
            setVehicleNumberValid(true); // Mark as valid
            // Open the modal with vehicle details after the form is populated
            setIsModalOpen(true);
            setIsSubmittingResponse(true);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const handleChange = (e) => {
        setVehicleNumberValid(false)
        setVehicleNoValue(e.target.value)
    }

    useEffect(() => {
        document.title = "Create Vehicle"
    }, [])

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["create-vehicle-padding"]}
            >
                <Grid item lg={10} md={9} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                        className="title-un-loading-text"
                    >
                        Create Vehicle
                    </Typography>
                </Grid>

                <Grid item lg={2} md={3} xs={12} container className={classes['action-button']}>
                    <UIButton
                        size="small"
                        variant="contained"
                        onClick={backToVehicleLists}
                        className={classes['back-button']}
                    >
                        <ArrowLeftOutlined />
                        &nbsp;Vehicles
                    </UIButton>
                </Grid>
            </Grid>

            <Form layout="vertical" form={createVehicleForm} onFinish={onFinish}>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["grid-space"]}
                >
                    <Grid item lg={6} md={6} xs={12} className={classes["position-relative"]}>
                        <Form.Item
                            label="Vehicle Number"
                            name="Vehicle_Number"
                            rules={[
                                {
                                    required: true, message: "Please enter the vehicle number"
                                },
                                {
                                    pattern: new RegExp(/^[A-Z]{2}[0-9]{2}([A-Z]{1,2})?[0-9]{4}$/i),
                                    message: "Please enter valid vehicle number"
                                }
                            ]}
                        >
                            <Input className={classes['width88']} onChange={handleChange} />
                        </Form.Item>
                        <div className={classes["input-field-challan-button"]}>
                            <Typography className={classes["stop-missing-icon"]} onClick={validateVehicleNumber}>Validate</Typography>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item
                            label="Owner"
                            name="Owner"
                            rules={[
                                {
                                    required: true, message: "Please enter the Owner Name"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["grid-space"]}
                >
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item name="No_of_Tyres" label="No of Tyres"
                            rules={[
                                {
                                    pattern: new RegExp(/^[0-9]+$/),
                                    message: "No of Tyres should be integer only"
                                }
                            ]}>
                            <Input type="number" min={0} max={20} defaultValue={0} />
                        </Form.Item>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item name="Remarks" label="Remarks">
                            <Input type="text" />
                        </Form.Item>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    className={classes["grid-space"]}
                >
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item label="Vehicle Gross Weight" name="Gross_Weight" rules={[
                            {
                                required: true,
                                message: "Please enter vehicle gross weight"
                            }
                        ]}>
                            <Input onKeyDown={AppHelper.disableNumberTypeArrowKeys} type="number" />
                        </Form.Item>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item label="Vehicle Category" name="Vehicle_Category" rules={[
                            {
                                required: true,
                                message: "Please enter vehicle category"
                            }
                        ]}>
                            <Input />
                        </Form.Item>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["grid-space"]}
                >
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item
                            label="Body Type"
                            name="Body_Type"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter vehicle body type"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    className={classes["grid-space"]}
                >
                    <Grid item lg={12} md={12} xs={12}>
                        <Form.Item
                            messageVariables=""
                            wrapperCol={{ span: 30 }}
                            labelCol={{ span: 30 }}
                            className={classes['ant-form-item-radio']}
                        >
                            <Checkbox value={true} onChange={onChangeCheckbox} disabled={vehicleNumberValid}>Forcefully Validated</Checkbox>
                        </Form.Item>
                    </Grid>
                </Grid>
                <div className={classes["submit-button-area"]}>
                    <UIButton variant="contained" color="primary" type="submit" className={classes['submit-button']} disabled={!isSubmittingResponse}>
                        Save
                    </UIButton>
                    <UIButton
                        variant="outlined"
                        color="error"
                        onClick={backToVehicleLists}
                    >
                        Cancel
                    </UIButton>
                </div>
            </Form >


            <Modal open={isModalOpen} footer={false} onCancel={handleCancel} centered className={classes["model-missing-challan"]} width={1000}>
                {
                    vehicleFetchInfo && Object.keys(vehicleFetchInfo)?.length > 0
                        ?
                        <>
                            <Typography variant="h6" color="initial" className={classes["missing-challan-heading"]}>Vehicle Details</Typography>
                            <div className={classes["missing-tags"]} style={{ height: 400, overflowY: 'scroll' }}>
                                {
                                    Object.entries(vehicleFetchInfo).filter(item => item?.[0] !== 'splitPresentAddress' && item?.[0] !== 'splitPermanentAddress').map(([value, key], i) => {
                                        return <>
                                            <div className={classes['vehicle-info-row']} style={{ width: constantsArr.LONG_LABELS_VEHICLE_INFO_TABLE.includes(value) ? '100%' : '49%' }}>
                                                <span className={classes['label']}>
                                                    {AppHelper.convertStringToLabel(value)}:
                                                </span>
                                                {(!key || key === '') ? 'NA' : key}
                                            </div>
                                            {++i % 2 === 0 ? <div className={classes['clear-both']}></div> : ""}
                                        </>
                                    })
                                }
                            </div>

                            <div className={classes["flex-items-missing"]}>
                                <Typography variant="body2" color="initial" className={classes["description-missing-challan"]}>

                                </Typography>
                            </div>
                        </>
                        :
                        <Typography variant="h6" color="initial" className={classes["missing-challan-heading"]}>No Vehicle information is available</Typography>
                }
            </Modal>


            <Modal
                open={isModalOpenInvalidVehicle}
                footer={[
                    <Button key="back" type="primary" onClick={handleCancelInvalidVehicle}>
                        OK
                    </Button>
                ]}
                onCancel={handleCancelInvalidVehicle}
                centered
                className={classes["model-missing-challan"]}
                width={1000}
                title={<div style={{ textAlign: 'center', color: '#f00', fontSize: '18px' }}>Error!</div>}
            >
                <p className={classes['error-message-popup']}>The vehicle number you entered does not belong to the HGV vehicle category, please check on <strong>Forcefully validated</strong> checkbox if you want to save this vehicle info.</p>
            </Modal>
            <Modal
                open={isModalOpenNonHGVVehicle}
                footer={false}
                onCancel={handleCancelNonHGVVehicle}
                centered
                className={classes["model-missing-challan"]}
                width={1000}
                title={<div style={{ textAlign: 'center', color: '#f00', fontSize: '18px' }}>Error!</div>}
            >
                <p className={classes['error-message-popup']}>Vehicle number entered is invalid, please check on <strong>Forcefully validated</strong> checkbox if you want to save this vehicle info.</p>
            </Modal>
        </>
    );
};
