import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import classes from "./EditUnloadedData.module.scss";
import { Form, Input, Upload, Modal, DatePicker, Select, notification, Checkbox, } from "antd";
import AppHelper from "../../../../helpers/AppHelper";
import dayjs from "dayjs";
import constantsArr from "../../../../constants/global-constants";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const EditUnloadedData = () => {
    const navigate = useNavigate();
    const params = useParams();
    const unLoadId = params?.id ?? "";
    const paramsQuery = new URLSearchParams(document.location.search);
    const pageNo = paramsQuery.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE
    const pageSize = paramsQuery.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE
    const [unLoadDetails, setUnLoadDetails] = useState("");
    const [vehicleLists, setVehicleLists] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [unLoadingAgencyDropDown, setUnLoadingAgencyDropDown] = useState([]);
    const [loadingForm] = Form.useForm();
    const [netWeight, setNetWeight] = useState(0);
    const [tareWeight, setTareWeight] = useState(0);
    const [reviewedBy, setReviewedBy] = useState("");
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileList, setFileList] = useState([]);
    const API_URL = process.env.REACT_APP_API_DOMAIN || "";
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
    const sessionUserInfo = AppHelper.decryptText(sessionStorage.getItem("userInfo"));
    const maxNoOfUploadsAllowed = constantsArr.MAX_UPLOAD_IMAGES
    const allowedMimeTypes = constantsArr.ALLOWED_MIME_TYPES
    const maxUploadSizeAllowed = constantsArr.MAX_UPLOAD_SIZE_MB * 1024 * 1024
    const [uploadedFileList, setUploadedFileList] = useState([]);
    const { search } = useLocation();
    const paramsBack = new URLSearchParams(search);
    const redirectbackto = paramsBack.get('redirectbackto');
    const backButtonLabel = redirectbackto ? 'Loadings' : 'Unloadings';
    const [viewPdf, setViewPdf] = useState(null);
    const dateFormat = "DD-MM-YYYY";
    const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);
    const [isLoadingBilled, setIsLoadingBilled] = useState(true)
    const [isUnloadingBilled, setIsUnloadingBilled] = useState(true)
    const [isTransportBilled, setIsTransportBilled] = useState(true)
    const [isClientBill, setIsClientBill] = useState(true)
    const [isUnloadingAgencyRateTypeDisabled, setIsUnloadingAgencyRateTypeDisabled] = useState(false)

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

    const transform = (slot) => ({
        ...slot,
        // These slots will be empty
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        CurrentPageInput: () => <></>,
        NumberOfPages: () => <></>,
        MoreActionsPopover: () => <></>,
        GoToFirstPage: () => <></>,
        GoToFirstPageMenuItem: () => <></>,
        GoToNextPage: () => <></>,
        GoToNextPageMenuItem: () => <></>,
        GoToPreviousPage: () => <></>,
        GoToPreviousPageMenuItem: () => <></>,
        GoToLastPage: () => <></>,
        GoToLastPageMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        ShowProperties: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
    });

    const getUnLoadingDetails = async () => {
        const response = await AppHelper.getUnloadingById(unLoadId);
        let uploadedPictures = response?.data?.attributes?.Uploaded_Pictures?.data?.attributes?.Pictures ?? "";
        if (uploadedPictures) {
            uploadedPictures = JSON.parse(uploadedPictures)
        }
        let updatedPictures = uploadedPictures.length > 0 ? uploadedPictures.map((image) => ({
            ...image,
            url: image?.url,
            uid: image?.id,
            name: image?.name,
            type: image?.mime,
            status: "done",
        })) : [];
        setNetWeight((!response?.data?.attributes?.Net_Weight || response?.data?.attributes?.Net_Weight === "" ? 0 : response?.data?.attributes?.Net_Weight))
        setTareWeight((!response?.data?.attributes?.Tare_Weight || response?.data?.attributes?.Tare_Weight === "" ? 0 : response?.data?.attributes?.Tare_Weight))
        setUnLoadDetails(response?.data);
        setFormInputValues(response?.data)
        setFileList(updatedPictures);
        setUploadedFileList(updatedPictures)
        setIsUnloadingAgencyRateTypeDisabled(true)
    };

    const putUnloadData = async (values) => {
        setIsSubmittingResponse(true);
        // upload images and set reference to unloading record
        // console.log(uploadedFileList, "uploadedFileList");
        // return false
        if (uploadedFileList.length === 0) {
            notification.error({
                message: "Error",
                description: "Please upload images of unloading",
                duration: 3,
            });
            return false
        }
        let filesJson = JSON.stringify(uploadedFileList)
        const uploadImageResponse = await AppHelper.savePictureData(filesJson)
        if (uploadImageResponse?.error) {
            notification.error({
                message: "Error",
                description: uploadImageResponse?.error,
                duration: 3,
            });
            return false
        } else {
            // check that unloading date is not less than loading date
            const dateUnloading = new Date(dayjs(values.Date_of_Unloading).format("YYYY-MM-DD"))
            const dateLoading = new Date(dayjs(unLoadDetails?.attributes?.Loading?.data?.attributes?.Date_of_Loading).format("YYYY-MM-DD"))
            if (dateUnloading < dateLoading) {
                notification.error({
                    message: "Error",
                    description: "Date of Unloading can not be less than loading date",
                    duration: 3,
                });
                return false
            }

            // Assuming fileId contains the IDs of the uploaded images
            const unloadingAgency = agencies.find((item) => item?.attributes?.Company_name === values?.Unloading_Agency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
            const transportingAgency = agencies.find((item) => item?.attributes?.Company_name === values?.Transporting_Agency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
            const vehicleNumber = vehicleLists?.data.find((item) => item?.Vehicle_Number === values?.Vehicle_Number)
            values['Date_of_Unloading'] = dayjs(values?.Date_of_Unloading).format("YYYY-MM-DD")
            values['Transporting_Agency'] = transportingAgency?.id
            values['Unloading_Agency'] = unloadingAgency?.id
            values['Vehicle_Number'] = vehicleNumber?.id
            values['Uploaded_Pictures'] = { 'id': uploadImageResponse?.data?.id ?? "" }
            values['Reviewed_By'] = reviewedBy === true ? sessionUserInfo?.userID : null
            values['Updated_By'] = sessionUserInfo?.userID ?? null
            values['Project'] = {
                id: sessionGlobalValues?.projectGlobal
            }
            const payload = { data: { ...values } };
            const response = await AppHelper.updateUnloading(unLoadId, payload);

            if (response?.error) {
                notification.error({
                    message: "Error",
                    description: response?.error?.message,
                    duration: 3,
                });
            } else {
                notification.success({
                    message: "Success",
                    description: "Unloading has been updated successfully.",
                    duration: 3
                });
                navigate(`/unloadings?sort=updatedAt:desc`);
            }
        }
        setIsSubmittingResponse(false);
    };

    const setFormInputValues = (response) => {
        setIsLoadingBilled(response?.attributes?.Loading?.data?.attributes?.is_loading_billed)
        setIsTransportBilled(response?.attributes?.Loading?.data?.attributes?.is_transporting_billed)
        setIsUnloadingBilled(response?.attributes?.Loading?.data?.attributes?.is_unloading_billed)
        setIsClientBill(response?.attributes?.Loading?.data?.attributes?.is_client_billed)

        loadingForm.setFieldsValue({
            Challan_Number: response?.attributes?.Challan_Number,
            Date_of_Unloading: dayjs(response?.attributes?.Date_of_Unloading),
            Vehicle_Number: response?.attributes?.Vehicle_Number?.data?.attributes?.Vehicle_Number,
            Owner: response?.attributes?.Vehicle_Number?.data?.attributes?.Owner,
            Thermal_Plant_Book_no: response?.attributes?.Thermal_Plant_Book_no,
            Kanta_Slip_No: response?.attributes?.Kanta_Slip_No,
            Gross_Weight: response?.attributes?.Gross_Weight ?? 0,
            Unloading_by_Employee: response?.attributes?.Unloading_by_Employee,
            Unloading_Agency: response?.attributes?.Unloading_Agency?.data?.attributes?.Company_name,
            Transporting_Agency: response?.attributes?.Transporting_Agency?.data?.attributes?.Company_name,
            Net_Weight: response?.attributes?.Net_Weight ?? 0,
            Tare_Weight: response?.attributes?.Tare_Weight ?? 0,
            Unloading_Location: response?.attributes?.Unloading_Location,
            Created_By: response?.attributes?.Created_By,
            Remarks: response?.attributes?.Remarks,
            Unloading_Rate_Type: response?.attributes?.Unloading_Rate_Type,
            Unloading_Rate_Value: response?.attributes?.Unloading_Rate_Value
        })
    }

    const onCheckboxChange = (e) => {
        setReviewedBy(e.target.checked)
    }

    const handleInputChange = (e) => {
        setIsSubmittingResponse(false);
        const { value, id } = e.target; // Assuming 'Date_of_Unloading' is the name of your date field
        if (id === "Net_Weight") {
            setNetWeight(parseFloat(value) || 0)
            loadingForm.setFieldsValue({ Gross_Weight: ((parseFloat(netWeight) + parseFloat(tareWeight)).toFixed(2)).toString() });
        } else if (id === "Tare_Weight") {
            setTareWeight(parseFloat(value) || 0)
            loadingForm.setFieldsValue({ Gross_Weight: ((parseFloat(netWeight) + parseFloat(tareWeight)).toFixed(2)).toString() });
        }
    };

    const onChangeUnloadingAgencyDropDown = (e) => {
        setIsSubmittingResponse(true)
        const agencyId = agencies.find((item) => item?.attributes?.Company_name === e.target.value && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
        const dateToMatch = dayjs(loadingForm.getFieldValue('Date_of_Unloading')).format("YYYY-MM-DD");
        const agencyType = e.target.agency_type
        AppHelper.getAgencyRateByAgencyIdAndType(agencyId?.id, agencyType, dateToMatch).then(response => {
            let fieldsValuesToBeSet = {}
            fieldsValuesToBeSet.Unloading_Rate_Type = response?.data?.attributes?.Rate_Type ?? 'per_mt'
            fieldsValuesToBeSet.Unloading_Rate_Value = response?.data?.attributes?.Rate ?? 0
            setIsUnloadingAgencyRateTypeDisabled(true)
            loadingForm.setFieldsValue(fieldsValuesToBeSet)
        }).catch(error => {
            notification.error({
                message: "Error",
                description: error?.message,
                duration: 3,
            });
        }).finally(() => {
            setIsSubmittingResponse(false)
        })
    }

    const customRequest = async ({ file, onSuccess, onError }) => {
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            return false
        }
        // check for maximum size can upload
        let uploadedSize = file?.size ?? 0
        uploadedSize = parseInt(uploadedSize)
        if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
            return false
        }
        const response = await AppHelper.uploadImages(file);
        if (response?.error) {
            onError(new Error("Upload failed"));
        } else {
            onSuccess();
            let imageResponse = response[0]
            Object.entries(imageResponse).forEach(([key, value]) => {
                if (key === 'url') {
                    imageResponse[key] = API_URL + value
                    imageResponse['uid'] = file?.uid
                }
            })
            uploadedFileList.push({ ...imageResponse });
        }
    };

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        console.log(file, 'file')
        if (file.type === 'application/pdf') {
            // Only set viewPdf if the file is a c/PDF
            setViewPdf(file.url || file.preview);
            setPreviewOpen(true);
            if (!viewPdf) {
                setPreviewImage(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        } else {
            setPreviewImage(file.url || file.preview);
            setPreviewOpen(true);
            if (!previewImage) {
                setViewPdf(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        }
    };

    const beforeUpload = (file) => {
        // check correct file type
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            notification.error({
                message: "Error",
                description: "The uploaded file is invalid; only files with the following extensions are accepted: jpg, jpeg, svg, bmp, and png.",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
        // check for maximum size can upload
        let uploadedSize = file?.size ?? 0
        uploadedSize = parseInt(uploadedSize)
        if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
            notification.error({
                message: "Error",
                description: "The uploaded file exceeds the maximum allowed image size; please ensure that the file size does not exceed 2 MB",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
    }

    const handleChange = (values) => {
        setIsSubmittingResponse(false);
        const fileStatus = values?.file?.status
        let fileList = values?.fileList
        if (fileStatus !== 'removed') {
            fileList = values?.fileList
            const uploadefFileType = values?.file?.type ?? ""
            if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
                return false || Upload.LIST_IGNORE
            } else {
                // check for maximum size can upload
                let uploadedSize = values?.file?.size ?? 0
                uploadedSize = parseInt(uploadedSize)
                if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
                    return false || Upload.LIST_IGNORE
                } else {
                    if (values?.file?.type === 'application/pdf') {
                        const getUploadedFile = values?.fileList.find((item) => item?.uid === values?.file?.uid)
                        let fileReader = new FileReader();
                        // Onload of file read the file content
                        fileReader.onload = function (fileLoadedEvent) {
                            // file = fileLoadedEvent.target.result;
                            // Print data in console
                            getUploadedFile.thumbUrl = fileLoadedEvent.target.result
                        };
                        // Convert data to base64
                        fileReader.readAsDataURL(values?.file?.originFileObj);
                    }
                }
            }
            if (values?.file?.type === 'application/pdf' && values?.file?.status !== 'done') {
                const getUploadedFile = values?.fileList.find((item) => item?.uid === values?.file?.uid)
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                    getUploadedFile.thumbUrl = URL.createObjectURL(e.target.result)
                }
            }
        } else {
            // remove from uploaded file array
            const uploadedFileListUpdated = uploadedFileList.filter((item) => item?.uid !== values?.file?.uid)
            setUploadedFileList(uploadedFileListUpdated)
            setFileList(fileList)
        }
        setFileList(values?.fileList);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    // const handleCheckboxChange = async () => {
    //   // Assuming you have a function to fetch reviewed_by value, replace 'fetchReviewedBy' with your actual function
    //   try {
    //     const response = await getUnLoadingDetails();
    //     const reviewedByValue = unLoadDetails?.attributes?.Reviewed_By ?? "";
    //     setReviewedBy(reviewedByValue);
    //   } catch (error) {
    //     console.error("Error fetching reviewed_by:", error);
    //   }

    //   // Toggle the checkbox state
    //   setChecked(!isChecked);
    // };

    const backToUnloadingsList = (e) => {
        e.preventDefault();
        if (redirectbackto === "true") {
            navigate(`/loadings?currentPage=${pageNo}&pageSize=${pageSize}`);
        } else {
            navigate(`/unloadings?currentPage=${pageNo}&pageSize=${pageSize}`);
        }
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        getUnLoadingDetails();

        AppHelper.getVehiclesWithoutPagination().then((response) => {
            setVehicleLists(response);
        });

        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then((response) => {
            setAgencies(response);
            setUnLoadingAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Unloading', sessionGlobalValues));
            //setTransportationAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Transportation', sessionGlobalValues));
        });
    }, []);

    useEffect(() => {
        document.title = "Edit Unloading"
        const updatedGrossWeight = ((parseFloat(netWeight ?? 0) + parseFloat(tareWeight ?? 0)).toFixed(2)).toString();
        loadingForm.setFieldsValue({ Gross_Weight: updatedGrossWeight });
    }, [netWeight, tareWeight]);

    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["edit-unloading-padding"]}
            >
                <Grid item lg={10} md={9} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                    >
                        Edit Unloading Details (Challan No: {unLoadDetails?.attributes?.Challan_Number})
                    </Typography>
                </Grid>
                <Grid item lg={2} md={3} xs={12} container className={classes['action-button']}>
                    <UIButton
                        variant="contained"
                        size="small"
                        onClick={backToUnloadingsList}
                        className={classes["add-new-entry"]}
                    >
                        <ArrowLeftOutlined />
                        &nbsp;{backButtonLabel}
                    </UIButton>
                </Grid>
            </Grid>
            <div className={classes["agency-details-margin"]}>
                <Form
                    layout="vertical"
                    form={loadingForm}
                    onFinish={putUnloadData}
                >
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                    >
                        <Grid item lg={6} md={6} sm={12}>
                            <Form.Item messageVariables="" label="Challan No." name="Challan_Number">
                                <Input disabled className={classes['disabled-input']} />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Vehicle Number" name="Vehicle_Number">
                                <Input disabled className={classes['disabled-input']} />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Thermal Plant Book No." name="Thermal_Plant_Book_no">
                                <Input
                                    onChange={handleInputChange}
                                    disabled
                                    className={classes['disabled-input']}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Unloading Kanta Slip No." name="Kanta_Slip_No"
                                rules={[
                                    { required: true, message: "Please enter the Unloading Kanta slip no" },
                                ]}>
                                <Input
                                    onChange={handleInputChange}
                                    readOnly={false}
                                />
                            </Form.Item>
                            <Form.Item
                                messageVariables=""
                                label="Tare Weight at Unloading (Kg)"
                                name="Tare_Weight"
                                rules={[
                                    { required: true, message: "Please enter the Tare weight." },
                                ]}
                            >
                                <Input
                                    type="number"
                                    onKeyDown={AppHelper.preventMinus}
                                    min={0}
                                    onChange={handleInputChange}
                                    readOnly={false}
                                    step="any"
                                    id="Tare_Weight"
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Unloading Agency" name="Unloading_Agency"
                                rules={[
                                    { required: true, message: "Please enter the Unloading agency." },
                                ]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    options={unLoadingAgencyDropDown}
                                    onChange={(e) => onChangeUnloadingAgencyDropDown({ target: { value: e, agency_type: 'unloading' } })}
                                    disabled={isUnloadingBilled}
                                    className={isUnloadingBilled ? classes['disabled-input'] : ""}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Employee at Unloading Point" name="Unloading_by_Employee">
                                <Input
                                    onChange={handleInputChange}
                                    readOnly={false}
                                />
                            </Form.Item>
                            <Form.Item label="Unloading Agency Rate Type" name="Unloading_Rate_Type" rules={[
                                { required: true, message: "Please select unloading agency" },
                            ]}>
                                <Select className={classes['disabled-input']} options={constantsArr.AGENCY_RATE_TYPES} placeholder="Select" disabled={isUnloadingAgencyRateTypeDisabled} />
                            </Form.Item>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                            <Form.Item
                                messageVariables=""
                                label="Date of Unloading"
                                name="Date_of_Unloading"
                                rules={[
                                    { required: true, message: "Please select Date of Unloading" },
                                ]}>
                                <DatePicker
                                    onChange={(date, dateString) =>
                                        handleInputChange({
                                            target: { name: "Date_of_Unloading", value: dateString },
                                        })
                                    }
                                    style={{ width: "100%" }}
                                    format={dateFormat}
                                    disabled={isTransportBilled || isUnloadingBilled || isClientBill}
                                    className={(isTransportBilled || isUnloadingBilled || isClientBill) ? classes['disabled-input'] : ""}
                                />
                            </Form.Item>
                            <Form.Item
                                messageVariables=""
                                label="Owner"
                                name="Owner"
                                onReset={setUnLoadDetails}
                            >
                                <Input
                                    onChange={handleInputChange}
                                    disabled
                                    className={classes['disabled-input']}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Transporting Agency" name="Transporting_Agency">
                                <Select
                                    showSearch
                                    disabled
                                    className={classes['disabled-input']}
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Net Weight at Unloading (Kg)" name="Net_Weight"
                                rules={[
                                    { required: true, message: "Please enter the Net weight" },
                                ]}>
                                <Input
                                    onChange={handleInputChange}
                                    readOnly={false}
                                    type="number"
                                    onKeyDown={AppHelper.preventMinus}
                                    min={0}
                                    step="any"
                                    id="Net_Weight"
                                    disabled={isTransportBilled || isLoadingBilled || isUnloadingBilled || isClientBill}
                                    className={(isTransportBilled || isLoadingBilled || isUnloadingBilled || isClientBill) ? classes['disabled-input'] : ""}
                                />
                            </Form.Item>
                            <Form.Item
                                messageVariables=""
                                label="Gross Weight at Unloading (Kg)"
                                name="Gross_Weight"
                                rules={[
                                    { required: true, message: "Please enter the Gross weight." },
                                ]}>
                                <Input
                                    step="any"
                                    onChange={handleInputChange}
                                    readOnly={true}
                                    onKeyDown={AppHelper.preventMinus}
                                    disabled
                                    className={classes['disabled-input']}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Unloading Location" name="Unloading_Location"
                                rules={[
                                    { required: true, message: "Please enter the Unloading location." },
                                ]}>
                                <Input
                                    onChange={handleInputChange}
                                    readOnly={false}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Remarks" name="Remarks">
                                <Input
                                    onChange={handleInputChange}
                                    readOnly={false}
                                />
                            </Form.Item>
                            <Form.Item label="Unloading Agency Rate Value" name="Unloading_Rate_Value" rules={[
                                { required: true, message: "Please enter unloading agency rate value" }
                            ]}>
                                <Input type="number" min={0} step={1} onKeyDown={AppHelper.disableNumberTypeArrowKeys} onKeyPress={AppHelper.allowNumbersOnly} />
                            </Form.Item>
                        </Grid>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                            className={classes["grid-position"]}
                        >
                            <Grid item lg={6} md={6} xs={12}>
                                {/* <Checkbox checked={isChecked} onChange={handleCheckboxChange}>
                  Reviewed By: {reviewedBy}
                </Checkbox> */}
                            </Grid>
                            <Grid item lg={3} md={5} xs={12} container spacing={3}>
                                <Grid item lg={6} md={6}>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        className={classes["grid-position"]}
                    >
                        <Grid item lg={6} md={6} xs={12}>
                            {
                                unLoadDetails?.attributes?.Created_By?.data?.id
                                    ?
                                    unLoadDetails?.attributes?.Created_By?.data?.id !== sessionUserInfo?.userID
                                        ?
                                        unLoadDetails?.attributes?.Reviewed_By?.data?.id
                                            ?
                                            <Form.Item name="Reviewed_By">
                                                <Checkbox value={true} checked disabled>
                                                    Review Done?
                                                </Checkbox>
                                            </Form.Item>
                                            :
                                            <Form.Item name="Reviewed_By">
                                                <Checkbox value={true} onChange={onCheckboxChange}>
                                                    Review Done?
                                                </Checkbox>
                                            </Form.Item>
                                        :
                                        ""
                                    :
                                    ""
                            }
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        className={classes["grid-position"]}
                    >
                        <Grid item lg={12} md={12} xs={12}>
                            <Upload
                                customRequest={customRequest}
                                multiple
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                name="Unloading_Images"
                                withCredentials={true}
                                beforeUpload={beforeUpload}
                                accept="image/png, image/jpeg, image/svg+xml, image/bmp, image/x-png, application/pdf"
                            >
                                {fileList.length >= maxNoOfUploadsAllowed ? null : uploadButton}
                            </Upload>
                            <Modal
                                open={previewOpen}
                                title={previewTitle}
                                footer={null}
                                width={800}
                                styles={{ body: { height: '500px', overflowY: 'scroll', maxHeight: '500px' } }}
                                onCancel={handleCancel}
                            >
                                <div
                                    className="rpv-core__viewer"
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.3)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    {viewPdf && (
                                        <>
                                            <div
                                                style={{
                                                    alignItems: 'center',
                                                    backgroundColor: '#eeeeee',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                    display: 'flex',
                                                    padding: '0.25rem',
                                                }}
                                            >
                                                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                                            </div>
                                            <>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.js`}>
                                                        <Viewer fileUrl={viewPdf} plugins={[toolbarPluginInstance]} />
                                                    </Worker>
                                                </div>
                                            </>
                                        </>
                                    )}
                                    {previewImage && ( // Render image if not viewing PDF
                                        <img
                                            alt="example"
                                            style={{
                                                width: "100%",
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </div>
                            </Modal>
                        </Grid>
                    </Grid>
                    <div className={classes["submit-button-area"]}>
                        <UIButton variant="contained" color="primary" type="submit" className={classes['submit-button']} disabled={isSubmittingResponse}>
                            Save
                        </UIButton>
                        <UIButton
                            variant="outlined"
                            return false
                            color="error"
                            onClick={backToUnloadingsList}
                        >
                            Cancel
                        </UIButton>
                    </div>
                </Form>
            </div>
        </>
    );
};
