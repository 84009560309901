import React, { useEffect, useState } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import classes from "./ClientBillFinalCalculation.module.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Form, Input, notification } from "antd";
import AppHelper from "../../../../helpers/AppHelper";
import dayjs from "dayjs";
import { BreadCrumbClientBilling } from "../BreadCrumbClientBilling/BreadCrumbClientBilling";
import exceljs from 'exceljs'
import FileSaver from 'file-saver'
import constantsArr from "../../../../constants/global-constants";

export const ClientBillFinalCalculation = () => {
    const navigate = new useNavigate();
    const activeButton = 'client_bill_final_calculation'; // Set the default active button
    const globalTPProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
    const [finalForm] = Form.useForm()
    const [totalPayableAmount, setTotalPayableAmount] = useState(0)
    const [cumAmount, setCUMAmount] = useState(0)
    const [diableSubmitButton, setDisableSubmitButton] = useState(false)
    const selectedLoadingIds = AppHelper.decryptText(sessionStorage.getItem('client_pending_bills_selected_loading_ids'))
    const billDataStorage = AppHelper.decryptText(sessionStorage.getItem('billDataStorage'))
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT;
    const onChange = (e) => {
        if (e.target.id === 'CUM') {
            // calculate gst on payable amount
            const value = e.target.value ? parseFloat(e.target.value) : 0
            setCUMAmount(parseFloat(value).toFixed(2))
        }
    };

    const findAgencyRateType = (rateType) => {
        return constantsArr.AGENCY_RATE_TYPES?.find((item) => item?.value === rateType)?.label ?? null
    }

    /**
     * Function to generate final bill with calculation with validation
     */
    const generateBillWithFinalCalculation = async (formValues) => {
        setDisableSubmitButton(true)
        const billPendingIds = makeSelectedIdsToBillSubmitFormat(selectedLoadingIds)
        const sortedBilledLoadingIds = billPendingIds.sort((first, second) => first?.loading - second?.loading)
        const finalBillData = {
            "trip_count": billDataStorage?.trip_count ?? 0,
            "unloading_starting_date": billDataStorage?.start_unloading_date ?? 0,
            "unloading_end_date": billDataStorage?.end_unloading_date ?? 0,
            "bill_no": formValues?.Bill_No ?? '',
            "bill_date": dayjs(billDataStorage?.bill_date).format('YYYY-MM-DD'),
            "net_weight": billDataStorage?.net_weight ?? 0,
            "cum": parseFloat(cumAmount ?? 0).toFixed(2),
            "total_net_weight": ((parseFloat(totalPayableAmount))).toFixed(2),
            "is_bill_final_settled": false,
            "Project": globalTPProjectValues?.projectGlobal,
            "loading_ids": sortedBilledLoadingIds
        }
        const response = await AppHelper.saveClientBill(finalBillData)
        if (response?.code === "ERR_NETWORK") {
            notification.error({
                message: "Network Error",
                description: response?.error?.message,
                duration: 3,
            });
            setDisableSubmitButton(false)
        } else if (response?.error) {
            notification.error({
                message: "Error",
                description: response?.error?.message,
                duration: 3,
            });
            setDisableSubmitButton(false)
        } else {

            // export functionality
            const exportResponse = await AppHelper.getClientBillDetails(response?.data?.id)
            if (exportResponse?.error) {
                notification.error({
                    message: "Error",
                    description: exportResponse?.error?.message,
                    duration: 3
                })
            } else {
                // export xlsx code goes here
                const workBook = new exceljs.Workbook()
                const workSheetPendingBills = workBook.addWorksheet("Vehicle List", {
                    views: [{ ySplit: 1, state: 'frozen' }]
                })
                workSheetPendingBills.columns = [
                    { header: 'S.NO.', key: "sl_no", width: 8 },
                    { header: 'Date of Loading', key: "Date_of_Loading", width: 20 },
                    { header: 'Loading Agency', key: "Loading_Agency", width: 20 },
                    { header: 'Loading Agency Rate', key: "Loading_Agency_Rate", width: 20 },
                    { header: 'Loading Agency Rate Type', key: "Loading_Agency_Rate_Type", width: 25 },
                    { header: 'Date of UL', key: "Date_of_Unloading", width: 20 },
                    { header: 'Unloading Agency', key: "Unloading_Agency", width: 20 },
                    { header: 'Unloading Agency Rate', key: "Unloading_Agency_Rate", width: 20 },
                    { header: 'Unloading Agency Rate Type', key: "Unloading_Agency_Rate_Type", width: 25 },
                    { header: 'Vehicle Number.', key: "Vehicle_Number", width: 15 },
                    { header: 'Challan No.', key: "Challan_Number", width: 15 },
                    { header: 'D/O No', key: "DO_No", width: 15 },
                    { header: 'HSD (LTR)', key: "HSD_LTR", width: 15 },
                    { header: 'HSD (LTR) Rate', key: "HSD_LTR_Rate", width: 15 },
                    { header: 'Transporter Agency', key: "Transporter_Agency", width: 20 },
                    { header: 'Transporter Agency Rate', key: "Transporter_Agency_Rate", width: 20 },
                    { header: 'Transporter Agency Rate Type', key: "Transporter_Agency_Rate_Type", width: 25 },
                    { header: 'Thermal Plant Book No', key: "Thermal_Plant_Book_no", width: 20 },
                    { header: 'Net Wt. UL (MT)', key: "Net_Weight", width: 20 },
                    { header: 'Gross weight (MT)', key: "Gross_Weight", width: 20 },
                    { header: 'Tare weight (MT)', key: "Tare_Weight", width: 15 },
                    { header: 'Kanta Slip No. UL', key: "Kanta_Slip_No", width: 25 },
                    { header: 'Unloading Location', key: "Unloading_Location", width: 20 },
                    { header: 'Loading Work Amount', key: "Loading_Work_Amount", width: 20 },
                    { header: 'Unloading Work Amount', key: "Unloading_Work_Amount", width: 20 },
                    { header: 'Transportation Work Amount', key: "Transportation_Work_Amount", width: 20 },
                    { header: 'HSD Amount', key: "HSD_AMOUNT", width: 20 },
                ]
                exportResponse?.loading_ids.forEach((billData, key) => {
                    const loadingAgencyRateType = findAgencyRateType(billData?.loading?.Loading_Rate_Type)
                    const unloadingAgencyRateType = findAgencyRateType(billData?.loading?.Unloading?.Unloading_Rate_Type)
                    const transpotationAgencyRateType = findAgencyRateType(billData?.loading?.Transportation_Rate_Type)
                    
                    const loadingNetWeight = parseFloat(billData?.loading?.Net_Weight)
    
                    billData.loading.sl_no = key + 1
                    billData.loading.Challan_Number = parseInt(billData?.loading?.Challan_Number)
                    billData.loading.Unloading_Location = billData?.loading?.Unloading?.Unloading_Location
                    billData.loading.Net_Weight = ((billData?.loading?.Unloading?.Net_Weight && billData?.loading?.Unloading?.Net_Weight !== "") ? parseFloat((billData?.loading?.Unloading?.Net_Weight / 1000).toFixed(2)) : 0)
                    billData.loading.Kanta_Slip_No = billData?.loading?.Unloading?.Kanta_Slip_No
                    billData.loading.Thermal_Plant_Book_no = billData?.loading?.Unloading?.Thermal_Plant_Book_no
    
                    billData.loading.Transporter_Agency = billData?.loading?.Transporter_Agency?.Company_name
                    billData.loading.Transporter_Agency_Rate = billData?.loading?.Transportation_Rate_Value ? parseFloat(billData?.loading?.Transportation_Rate_Value) : ""
                    billData.loading.Transporter_Agency_Rate_Type = transpotationAgencyRateType
    
                    billData.loading.Unloading_Agency = billData?.loading?.Unloading?.Unloading_Agency?.Company_name
                    billData.loading.Unloading_Agency_Rate = billData?.loading?.Unloading?.Unloading_Rate_Value ? parseFloat(billData?.loading?.Unloading?.Unloading_Rate_Value) : ""
                    billData.loading.Unloading_Agency_Rate_Type = unloadingAgencyRateType
    
                    billData.loading.HSD_LTR = billData?.loading?.hsd_detail?.HSD_LTR ? parseFloat(billData?.loading?.hsd_detail?.HSD_LTR.toFixed(2)) : ""
                    billData.loading.HSD_LTR_Rate = billData?.loading?.hsd_detail?.Rate_INR ? parseFloat(billData?.loading?.hsd_detail?.Rate_INR) : ""
                    billData.loading.Tare_Weight = ((billData?.loading?.Unloading?.Tare_Weight && billData?.loading?.Unloading?.Tare_Weight !== "") ? parseFloat((billData?.loading?.Unloading?.Tare_Weight / 1000).toFixed(2)) : 0)
                    billData.loading.Gross_Weight = ((billData?.loading?.Unloading?.Gross_Weight && billData?.loading?.Unloading?.Gross_Weight !== "") ? parseFloat((billData?.loading?.Unloading?.Gross_Weight / 1000).toFixed(2)) : 0)
                    billData.loading.Vehicle_Number = billData?.loading?.Vehicle_Number?.Vehicle_Number
    
                    billData.loading.Loading_Agency = billData?.loading?.Loading_Agency?.Company_name
                    billData.loading.Loading_Agency_Rate = billData?.loading?.Loading_Rate_Value ? parseFloat(billData?.loading?.Loading_Rate_Value) : ""
                    billData.loading.Loading_Agency_Rate_Type = loadingAgencyRateType
    
                    billData.loading.Date_of_Unloading = dayjs(billData?.loading?.Unloading?.Date_of_Unloading).format(dateFormat)
                    billData.loading.Date_of_Loading = dayjs(billData?.loading?.Date_of_Loading).format(dateFormat)
    
                    let loadingWorkAmount = 0
                    let unloadingWorkAmount = 0
                    let transportationWorkAmount = 0
    
                    if (loadingAgencyRateType === 'Per (MT)')
                        loadingWorkAmount = loadingNetWeight * billData.loading.Loading_Agency_Rate
                    else if (loadingAgencyRateType === 'Per (Unit)')
                        loadingWorkAmount = parseFloat(billData?.loading?.Loading_Rate_Value)
                    else
                        loadingWorkAmount = 0
    
                    if (unloadingAgencyRateType === 'Per (MT)')
                        unloadingWorkAmount = billData.loading.Net_Weight * billData.loading.Unloading_Agency_Rate
                    else if (unloadingAgencyRateType === 'Per (Unit)')
                        unloadingWorkAmount = billData.loading.Unloading_Agency_Rate
                    else
                        unloadingWorkAmount = 0
    
                    if (transpotationAgencyRateType === 'Per (MT)')
                        transportationWorkAmount = loadingNetWeight * billData.loading.Transporter_Agency_Rate
                    else if (transpotationAgencyRateType === 'Per (Unit)')
                        transportationWorkAmount = billData.loading.Transporter_Agency_Rate
                    else
                        transportationWorkAmount = 0
    
                    billData.loading.Loading_Work_Amount = loadingWorkAmount
                    billData.loading.Unloading_Work_Amount = unloadingWorkAmount
                    billData.loading.Transportation_Work_Amount = transportationWorkAmount
                    billData.loading.HSD_AMOUNT = billData?.loading?.hsd_detail?.HSD_Amt ? parseFloat(billData?.loading?.hsd_detail?.HSD_Amt) : 0
    
                    workSheetPendingBills.addRow(billData?.loading)
                })

                // preapare sheet for global counts

                const workSheetPendingBillGlobalCounts = workBook.addWorksheet("Transaction Summary")
                // prepare sheet columns / headers
                workSheetPendingBillGlobalCounts.columns = [
                    { header: 'TP', key: "first_value", width: 25 },
                    { header: exportResponse?.Project?.Thermal_Plant?.Name, key: "second_value", width: 25 },
                ]

                // prepare sheet rows to display
                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Project", second_value: exportResponse?.Project?.Name_of_project ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Bill Date", second_value: exportResponse?.bill_date ? dayjs(exportResponse?.bill_date).format(dateFormat) : '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Bill No.", second_value: exportResponse?.bill_no ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Trip Count", second_value: exportResponse?.trip_count ?? 0 }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Starting Date (UL)", second_value: exportResponse?.unloading_starting_date ? dayjs(exportResponse?.unloading_starting_date).format(dateFormat) : '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "End Date (UL)", second_value: exportResponse?.unloading_end_date ? dayjs(exportResponse?.unloading_end_date).format(dateFormat) : '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Net Weight (UL)", second_value: exportResponse?.total_net_weight ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "CUM", second_value: exportResponse?.cum ?? 0 }
                )


                // set header to export as excel file
                try {
                    const buffer = await workBook.xlsx.writeBuffer()
                    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    const excelExtension = constantsArr.EXPORT_EXCEL_EXTENSION
                    const blob = new Blob([buffer], { type: fileType })
                    FileSaver.saveAs(blob, `client_bill_save_export_data_${dayjs().format(dateFormat)}.${excelExtension}`)
                } catch (error) {
                    console.log(error, 'error');
                    notification.error({
                        message: "Error",
                        description: exportResponse?.error?.message,
                        duration: 3
                    })
                }
            }

            notification.success({
                message: "Success",
                description: "Client bill generated successfully",
                duration: 3,
            });

            // clear session storage items related to bills
            sessionStorage.removeItem('billDataStorage')
            sessionStorage.removeItem('transport_pending_bills_to_generate_ids')
            sessionStorage.removeItem('selected_bill_date')
            sessionStorage.removeItem('client_pending_bills_selected_loading_ids')
            sessionStorage.removeItem('client_pending_bill_active_sort_order')
            sessionStorage.removeItem('client_bill_pending_screen_filter_values')
            setDisableSubmitButton(false)
            navigate("/bills/client-bill/generated-client-bill");
        }
    }

    const makeSelectedIdsToBillSubmitFormat = (loadingIds) => {
        if (loadingIds.length === 0) return []
        let finalIdsArray = []
        for (let index = 0; index < loadingIds.length; index++) {
            finalIdsArray.push(
                {
                    "id": index,
                    "loading": loadingIds[index]
                }
            )
        }

        return finalIdsArray
    }

    const backToGenerateBill = () => {
        navigate("/bills/client-bill/generate")
    }

    useEffect(() => {
        setTotalPayableAmount(billDataStorage?.net_weight ?? 0 ?? 0)
        finalForm.setFieldsValue({
            Bill_No: billDataStorage?.bill_no
        })
        document.title = "Client Bill Final Calculation"
    }, [])

    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["transport-final-calculation"]}
            >
                <Grid item lg={10} md={8} sm={8} xs={12}>
                    <BreadCrumbClientBilling
                        activeButton={activeButton}
                        page="clientBill"
                    />
                </Grid>
                <Grid item lg={2} md={4} sm={4} xs={12} className={classes['right-align']}>
                    <UIButton
                        variant="contained"
                        className={classes["back-new-entry"]}
                        startIcon={<ArrowLeftOutlined />}
                        onClick={backToGenerateBill}
                    >
                        Generate Bills
                    </UIButton>
                </Grid>
            </Grid>
            <Form layout="vertical" form={finalForm} onFinish={generateBillWithFinalCalculation}>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["transport-final-calculation-padding"]}
                >
                    <Grid item lg={6} md={12} xs={12}>
                        <Grid className={classes["final-track-first"]}>

                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-second"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Trip Count :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {billDataStorage?.trip_count ?? 0}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-third"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Start date of Unloading:
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {dayjs(billDataStorage?.start_unloading_date).format("DD-MM-YYYY")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-fourth"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        End date of Unloading :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {dayjs(billDataStorage?.end_unloading_date).format("DD-MM-YYYY")}
                                    </Typography>
                                </Grid>
                            </Grid><Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-fourth"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Billing Date :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {dayjs(billDataStorage?.bill_date).format("DD-MM-YYYY")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}>
                        <Grid className={classes["final-track-second"]}>
                            <Grid className={classes["billing-prefix-form"]}>
                                <Grid container spacing={0}>
                                    <Grid container item lg={6} md={6} xs={12}>
                                        <Form.Item label="Bill No." name="Bill_No" style={{ width: '95%' }} rules={
                                            [
                                                {
                                                    required: true,
                                                    message: 'Please enter bill no'
                                                }
                                            ]
                                        }>
                                            <Input className={classes["input-field"]} />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Form.Item label="CUM" name="CUM">
                                            <Input type="number" step={0.01} min={0} className={classes["input-field"]} onChange={onChange} onKeyPress={AppHelper.preventNegativeValueForCUM} />
                                        </Form.Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes["total-amount-wrapper"]}>
                                <Grid container spacing={0}>
                                    <Grid item lg={6} md={6} xs={6}>
                                        <Typography variant="h5" color="#fff">
                                            Net Weight (MT)
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={6}>
                                        <Typography variant="h4" color="#fff" fontWeight="bold" className={classes["amount-bold"]}>
                                            {(parseFloat(totalPayableAmount)).toLocaleString('en-IN')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <div className={classes["submit-button-area"]}>
                            <UIButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={diableSubmitButton}
                                className={classes["submit-button"]}
                            >
                                Save and Export
                            </UIButton>
                        </div>
                    </Grid>
                </Grid>
            </Form>
        </>
    );
};
