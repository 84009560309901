import React, { useEffect, useState } from "react";
import { Card, Spin, Pagination, Drawer, Input, Form, Select, Popconfirm, notification } from "antd";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import { DeleteOutlined, EditOutlined, EyeOutlined, FilterOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import classes from "./PetrolPumpData.module.scss";
import { Link, useNavigate } from "react-router-dom";
import "../../../styles/index.scss";
import constantsArr from "../../../constants/global-constants";
import AppHelper from "../../../helpers/AppHelper";
import totalCountImg from "../../../assets/images/HeaderIcons/totalcount.svg"

export const PetrolPumpData = () => {
    const navigate = useNavigate();
    const params = new URLSearchParams(document.location.search);
    const [petrolPumpData, setPetrolPumpData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showPagination, setShowPagination] = useState(true);
    const [currentPagePagination, setCurrentPagePagination] = useState(params.get('currentPage') ?? 1);
    const [currentPageSizePagination, setCurrentPageSizePagination] = useState(constantsArr.DEFAULT_PAGINATION_SIZE);
    const [open, setOpen] = useState(false);
    const [petrolPumpFilterForm] = Form.useForm()
    const [currentPage, pageSize] = [params.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE, params.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE];
    const [currentSetFilterValues, setCurrentSetFilterValues] = useState({})
    const [sortValue, setSortValue] = useState('updatedAt:desc'); // Default sorting
    const [sortSpinning, setSortSpinning] = useState(false);
    const [petrolPumpsDropDownValues, setPetrolPumpsDropDownValues] = useState([]);
    const [petrolPumpsLists, setPetrolPumpsLists] = useState([]);
    const sessionPetrolPumpDetailsFilterValues = AppHelper.decryptText(sessionStorage.getItem('petrol_pump_schema_filter_form_values'))
    const sessionPetrolPumpDetailsSchemaSortOrder = AppHelper.decryptText(sessionStorage.getItem('petrol_pump_schema_sort_order_value'))


    const changePageNumber = (currentPage, pageSize) => {
        setCurrentPageSizePagination(pageSize);
        petrolPumpsData(currentPage, pageSize, sessionPetrolPumpDetailsFilterValues);
    };

    const redirectToCreatePetrolPump = () => navigate("/petrol-pumps/create");

    const handleDelete = (petrolPumpId) => {
        setLoading(true);
        AppHelper.deletePetrolPump(petrolPumpId).then((response) => {
            setLoading(false);
            if (response?.error) {
                notification.error({
                    message: 'Unable to delete Petrol pump record',
                    description: response?.error?.response.data || 'Unknown error',
                    duration: 3,
                });

            } else {
                notification.success({
                    message: "Success",
                    description: "Petrol pump record has been deleted successfully",
                    duration: 1.5,
                });
                petrolPumpsData(((sessionPetrolPumpDetailsSchemaSortOrder && sessionPetrolPumpDetailsSchemaSortOrder !== "") ? sessionPetrolPumpDetailsSchemaSortOrder : sortValue), currentPagePagination, currentPageSizePagination, sessionPetrolPumpDetailsFilterValues);
            }
        });
    };

    const petrolPumpsData = async (sortOrder, currentPage, pageSize, filterArgs = {}) => {
        setCurrentPagePagination(currentPage);
        AppHelper.getAllPetrolPumps(sortOrder, currentPage, pageSize, true, filterArgs).then((response) => {
            setSortSpinning(false);
            setLoading(false);
            if (response?.error) {
                console.log(response?.error?.data);
            } else {
                setPetrolPumpData(response);
                setShowPagination(true);
            }
        });
    };

    const showDrawer = () => setOpen(true);

    const onClose = () => setOpen(false);

    const filterPetrolPumps = async (formValues) => {
        setOpen(false)
        setCurrentSetFilterValues(formValues)
        setSortSpinning(true);
        sessionStorage.setItem('petrol_pump_schema_filter_form_values', AppHelper.encryptText(formValues))
        petrolPumpsData(((sessionPetrolPumpDetailsSchemaSortOrder && sessionPetrolPumpDetailsSchemaSortOrder !== "") ? sessionPetrolPumpDetailsSchemaSortOrder : sortValue), currentPage, pageSize, AppHelper.decryptText(sessionStorage.getItem('petrol_pump_schema_filter_form_values')));
    }

    const resetFilterForm = (e) => {
        e.preventDefault()
        petrolPumpFilterForm.resetFields()
        sessionStorage.removeItem('petrol_pump_schema_filter_form_values')
    }

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const handleSortChange = (value) => {
        setSortSpinning(true);
        // Update the sorting state
        setSortValue(value);
        setCurrentPagePagination(currentPage)
        petrolPumpsData(value, 1, pageSize, currentSetFilterValues);
        sessionStorage.setItem('petrol_pump_schema_sort_order_value', AppHelper.encryptText(value))
    };


    useEffect(() => {
        document.title = "Petrol Pump List";
        AppHelper.getPetrolPumps().then((response) => {
            setPetrolPumpsLists(response);
            setPetrolPumpsDropDownValues(AppHelper.getPetrolPumpsDropDown(response));
        });
        if (sessionPetrolPumpDetailsFilterValues && Object.keys(sessionPetrolPumpDetailsFilterValues).length > 0) {
            // remove `Date` key from session object to prevent overlapping
            petrolPumpFilterForm.setFieldsValue({
                Petrolpump_Name: sessionPetrolPumpDetailsFilterValues.Petrolpump_Name,
                Contact_person: sessionPetrolPumpDetailsFilterValues.Contact_person,
                Contact_Number: sessionPetrolPumpDetailsFilterValues.Contact_Number,
                Location: sessionPetrolPumpDetailsFilterValues.Location
            })
        }
        petrolPumpsData(((sessionPetrolPumpDetailsSchemaSortOrder && sessionPetrolPumpDetailsSchemaSortOrder !== "") ? sessionPetrolPumpDetailsSchemaSortOrder : sortValue), currentPage, pageSize, sessionPetrolPumpDetailsFilterValues);
    }, []);

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes['header-fixed-petrol-pump']}
            >
                <Grid item lg={9} md={8} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                        className="title-un-loading-text"
                    >
                        Petrol Pumps
                    </Typography>
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={4}
                    xs={12}
                    container
                    spacing={2}
                    className={classes["action-button"]}
                >
                    <Grid item lg={4} md={4} xs={12} className={classes["form-select-div-select"]}>
                        <Form>
                            <Form.Item label="Sort By">
                                <Select className={classes["select-new-entry"]} defaultValue={((sessionPetrolPumpDetailsSchemaSortOrder && sessionPetrolPumpDetailsSchemaSortOrder !== "") ? sessionPetrolPumpDetailsSchemaSortOrder : sortValue ?? "updatedAt:desc")} onChange={handleSortChange}>
                                    <Select.Option value="updatedAt:desc">Updated Date</Select.Option>
                                    <Select.Option value="Petrolpump_Name:asc">Petrol Pump Name Ascending</Select.Option>
                                    <Select.Option value="Petrolpump_Name:desc">Petrol Pump Name Descending</Select.Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Grid>
                    <Grid item lg={4} md={4} xs={6}>
                        <UIButton
                            onClick={showDrawer}
                            variant="outlined"
                            startIcon={<FilterOutlined />}
                            className={classes["filter-new-entry"]}
                        >
                            Filters
                        </UIButton>
                        <Drawer title="Filters" onClose={onClose} open={open}>
                            <Form layout="vertical" form={petrolPumpFilterForm} onFinish={filterPetrolPumps}>
                                <Form.Item name="Petrolpump_Name" label="Petrol Pump Name">
                                    <Select
                                        showSearch
                                        addonBefore={<SearchOutlined />}
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        options={petrolPumpsDropDownValues}
                                        placeholder="Select"
                                    />
                                </Form.Item>

                                <Form.Item label="Contact Person" name="Contact_person">
                                    <Input
                                        placeholder="Enter Contact Person Name"
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item label="Contact Number" name="Contact_Number">
                                    <Input
                                        type="number"
                                        placeholder="Enter Contact Number"
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item label="Location" name="Location">
                                    <Input
                                        placeholder="Enter Location"
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <div className={classes["search-button-area"]}>
                                    <UIButton size="medium" variant="outlined" color="error" onClick={resetFilterForm}>
                                        Reset
                                    </UIButton>
                                    <UIButton
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes["new-filter-apply"]}
                                    >
                                        Apply
                                    </UIButton>
                                </div>
                            </Form>
                        </Drawer>
                    </Grid>
                    <Grid item lg={4} md={4} xs={6}>
                        <UIButton
                            size="small"
                            variant="contained"
                            className={classes["add-new-entry"]}
                            onClick={redirectToCreatePetrolPump}
                        >
                            + Add new
                        </UIButton>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} xs={12} container spacing={1}>
                    <Grid item lg={12} md={12} xs={12}>
                        <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-first"]}`} fontSize={14}>
                            <div className={classes["header-grid-flex"]}>
                                <div><img src={totalCountImg} alt="Total count" /></div>
                                <div>
                                    <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={petrolPumpData?.meta?.pagination?.total ?? 0}>
                                        {AppHelper.formatNumber(petrolPumpData?.meta?.pagination?.total ?? 0)}
                                    </Typography><br></br>
                                    <Typography variant="body" color="white">
                                        Total Count
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <>
                    <Grid container spacing={0} className={classes["marginTop20"]}>
                        {!petrolPumpData?.data || sortSpinning ? (
                            <Spin size="large" tip="Please Wait ...">
                                <div className={classes["content"]} />
                            </Spin>
                        ) : petrolPumpData?.data?.length === 0 ? (
                            // Show message when data is not available
                            <Typography variant="body1">
                                No petrol pump record is available
                            </Typography>
                        ) : (petrolPumpData?.data?.map((item) => (
                            <Grid key={item.id} item lg={4} md={6} xs={12}>
                                <Card bordered={true} className={classes["card-box"]}>
                                    <div className={classes["AgencyDetails"]}>
                                        <Typography
                                            component="div"
                                            gutterBottom
                                            fontWeight="bold"
                                        >
                                            {item?.attributes?.Petrolpump_Name ?? "-"}
                                        </Typography>
                                        <Typography gutterBottom variant="body2">
                                            Location :
                                            <span className={classes["AgencyDetailsValue"]}>
                                                {item?.attributes?.Location ?? "-"}
                                            </span>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Contact Person:
                                            <span className={classes["AgencyDetailsValue"]}>
                                                {item?.attributes?.Contact_person ?? "-"}
                                            </span>
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            Contact Number:
                                            <span className={classes["AgencyDetailsValue"]}>
                                                {item?.attributes?.Contact_Number ?? "-"}
                                            </span>
                                        </Typography>
                                    </div>

                                    <div className={classes["card-button"]}>
                                        <ul className={classes["action-items"]}>
                                            <li>
                                                <Link to={`/petrol-pumps/view/${item?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}`}>
                                                    <EyeOutlined
                                                        className={classes["card-icons"]}
                                                        style={{ color: "#000" }}
                                                    />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/petrol-pumps/edit/${item?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}`}>
                                                    <EditOutlined
                                                        className={classes["card-icons"]}
                                                        style={{ color: "#000" }}
                                                    />
                                                </Link>
                                            </li>
                                            <li>
                                                <Popconfirm
                                                    okText="Yes"
                                                    cancelText="No"
                                                    title="Delete the agency"
                                                    description="Are you sure, you want to delete this petrol pump record?"
                                                    onConfirm={e => handleDelete(item?.id)}
                                                    icon={<QuestionCircleOutlined style={{ color: '#ff0000' }} />}
                                                >
                                                    <DeleteOutlined
                                                        className={classes["card-icons"]}
                                                        style={{ color: "#ff0000" }}
                                                    />
                                                </Popconfirm>
                                            </li>
                                        </ul>
                                    </div>
                                </Card>
                            </Grid>
                        ))
                        )}

                    </Grid>
                </>
                {showPagination === true && petrolPumpData?.data?.length > 0 ? (
                    <div className={classes["pagination-box"]}>
                        <Pagination
                            current={currentPagePagination}
                            defaultCurrent={currentPage}
                            defaultPageSize={currentPageSizePagination}
                            showTotal={(total) => `Total ${total} items`}
                            responsive
                            pageSize={currentPageSizePagination}
                            onChange={changePageNumber}
                            showSizeChanger={false}
                            total={petrolPumpData?.meta?.pagination?.total}
                        />
                    </div>
                ) : (
                    ""
                )}
            </Grid>
        </>
    );
};
