import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import { ArrowLeftOutlined } from "@ant-design/icons";

import classes from "./ViewVehicleData.module.scss";
import { Checkbox, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/global-constants";
import html2pdf from 'html2pdf.js'
import downloadPDFIcon from '../../../../assets/images/download-pdf.png'

export const ViewVehicleData = () => {
    const navigate = useNavigate();
    const params = useParams();
    const loadId = params?.id ?? "";
    const paramsQuery = new URLSearchParams(document.location.search);
    const pageNo = paramsQuery.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE
    const pageSize = paramsQuery.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE
    const [vehicleDetails, setVehicleDetails] = useState("");

    const handleDownloadPdf = async () => {

        let element = '<h1 style="padding:25px 0;text-align:center;">Vehicle Details</h1>'

        element += `<div>`
        {
            Object.entries(vehicleDetails?.attributes?.Vehicle_Information).filter(
                item => item?.[0] !== 'splitPresentAddress' &&
                    item?.[0] !== 'splitPermanentAddress' && item?.[0] !== 'grossVehicleWeight' && item?.[0] !== 'bodyType'
            ).map(([value, key], i) => {

                const widthPercentage = constantsArr.LONG_LABELS_VEHICLE_INFO_TABLE.includes(value) ? '100%' : '49%'

                element += `<div style="font-size: 18px;float: left;padding: 10px 0px;border-bottom: 1px solid #ccc;width:${widthPercentage}">
                <span style="font-weight: bold;color: #444CF7 !important;padding: 10px !important;margin-right: 10px !important;">`
                element += `${AppHelper.convertStringToLabel(value)}`

                element += `:</span>`
                element += `${(!key || key === '') ? 'NA' : key}`
                element += `</div>`
                {
                    ++i % 2 === 0 ?
                        element += `<div style="clear: both;"></div>`
                        :
                        element += ""
                }
            })
        }

        element += `<div style="font-size: 18px;float: left;padding: 10px 0px;border-bottom: 1px solid #ccc;width:${'49%'}">
                    <span style="font-weight: bold;color: #444CF7 !important;padding: 10px !important;margin-right: 10px !important;">`
        element += `Gross Vehicle Weight`
        element += `:</span>`
        element += `${(!vehicleDetails?.attributes?.Gross_Weight || vehicleDetails?.attributes?.Gross_Weight === '') ? 'NA' : vehicleDetails?.attributes?.Gross_Weight}`
        element += `</div>`
        element += `<div style="font-size: 18px;float: left;padding: 10px 0px;border-bottom: 1px solid #ccc;width:${'49%'}">
                    <span style="font-weight: bold;color: #444CF7 !important;padding: 10px !important;margin-right: 10px !important;">`
        element += `Body Type`
        element += `:</span>`
        element += `${(!vehicleDetails?.attributes?.Body_Type || vehicleDetails?.attributes?.Body_Type === '') ? 'NA' : vehicleDetails?.attributes?.Body_Type}`
        element += `</div>`
        element += `</div>`
        element += `</div>`

        const options = {
            margin: 1.0,
            filename: 'vehicle_information_details.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 3 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'l', putOnlyUsedFonts: true, floatPrecision: 16 }
        };

        html2pdf().from(element).set(options).save();
    };

    const getVehiclesDetails = async () => {
        const response = await AppHelper.getVehicleById(loadId);
        setVehicleDetails(response?.data);
    };

    const backToVehiclesList = (e) => {
        e.preventDefault();
        navigate(`/vehicles?currentPage=${pageNo}& pageSize=${pageSize}`);
    };

    useEffect(() => {
        document.title = "View Vehicle Details"
        getVehiclesDetails();
    }, []);


    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["view-vehicle-padding"]}
            >
                <Grid item lg={10} md={9} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                        className="title-un-loading-text"
                    >
                        View Vehicle Details
                    </Typography>
                </Grid>
                <Grid item lg={2} md={3} xs={12} container className={classes['action-button']}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={backToVehiclesList}
                        className={classes['back-button']}
                    >
                        <ArrowLeftOutlined />
                        &nbsp;Vehicles
                    </Button>
                </Grid>
            </Grid>
            <Form layout="vertical">
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["grid-space"]}
                >
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item label="Vehicle Number">
                            <Input
                                readOnly
                                value={vehicleDetails?.attributes?.Vehicle_Number ?? ""}
                                name="Vehicle_Number"
                            />
                        </Form.Item>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item
                            label="Owner"
                        >
                            <Input
                                readOnly
                                value={vehicleDetails?.attributes?.Owner ?? ""}
                                name="Owner"

                            />
                        </Form.Item>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["view-vehicle-padding"]}
                >
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item label="No of Tyres">
                            <Input
                                readOnly
                                value={vehicleDetails?.attributes?.No_of_Tyres ?? 0}
                                name="No_of_Tyres"
                            />
                        </Form.Item>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item label="Remarks">
                            <Input
                                readOnly
                                value={vehicleDetails?.attributes?.Remarks ?? ""}
                                name="Remarks"

                            />
                        </Form.Item>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["view-vehicle-padding"]}
                >
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item label="Vehicle Gross Weight">
                            <Input
                                readOnly
                                value={vehicleDetails?.attributes?.Gross_Weight ?? 0}
                                name="Gross_Weight"
                            />
                        </Form.Item>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item label="Vehicle Category">
                            <Input
                                readOnly
                                value={vehicleDetails?.attributes?.Vehicle_Category ?? ""}
                                name="Vehicle_Category"

                            />
                        </Form.Item>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["view-vehicle-padding"]}
                >
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item label="Body Type">
                            <Input
                                readOnly
                                value={vehicleDetails?.attributes?.Body_Type ?? 0}
                                name="bodyType"
                            />
                        </Form.Item>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    className={classes["view-vehicle-padding"]}
                >
                    <Grid item lg={12} md={12} xs={12}>
                        <Form.Item
                            messageVariables=""
                            wrapperCol={{ span: 30 }}
                            labelCol={{ span: 30 }}
                            className={classes['ant-form-item-radio']}
                        >
                            <Checkbox value={vehicleDetails?.attributes?.Forcefully_Validated} checked={vehicleDetails?.attributes?.Forcefully_Validated} disabled>Forcefully Validated</Checkbox>
                        </Form.Item>
                    </Grid>
                </Grid>
                {
                    vehicleDetails?.attributes?.Vehicle_Information && Object.keys(vehicleDetails?.attributes?.Vehicle_Information).length > 0
                        ?
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="wrap"
                            className={classes["view-vehicle-padding"]}
                        >
                            <Grid item lg={12} md={12} xs={12}>
                                <>
                                    <button type="button" title="Download Vehicle Details PDF" className={classes['download_vehicle_details_pdf']}><img src={downloadPDFIcon} alt="Download Vehicle Details PDF" onClick={handleDownloadPdf} width={64} /></button>
                                </>
                            </Grid>
                        </Grid>
                        :
                        ""
                }
            </Form>
        </>
    );
};
