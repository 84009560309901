import React, { useEffect, useState } from "react";
import { Checkbox, notification, Spin, Table } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import classes from "./TransporterBilled.module.scss";
import AppHelper from "../../../../../../helpers/AppHelper";
import constantsArr from "../../../../../../constants/global-constants";
import dayjs from "dayjs";
import { Button, Grid } from "@mui/material";
import exceljs from 'exceljs'
import FileSaver from 'file-saver'

const columnsUnBilled = [
    {
        title: "S. no.",
        dataIndex: "s_no",
    },
    {
        title: "Bill Amount",
        dataIndex: "bill_amount",
    },
    {
        title: "GST",
        dataIndex: "gst",
    },
    {
        title: "Total",
        dataIndex: "total",
    },
    {
        title: "HSD Deduction Amount",
        dataIndex: "hsd_deduction_amount",
    },
    {
        title: "TDS Deduction",
        dataIndex: "tds_deduction",
    },
    {
        title: "Other Deduction",
        dataIndex: "other_deduction",
    },
    {
        title: "Unbilled Payable",
        dataIndex: "unbilled_payable",
    },
];

export const TransporterBilled = (props) => {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT
    const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(false)
    const [withHeldGSTRecordIdsTransport, setWithHeldGSTRecordIdsTransport] = useState([])
    const [unbilledTableData, setTableUnBilledData] = useState(false)
    const exportPageSize = constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE
    const [tableData, setTableData] = useState([])

    const columnsBilledTransport = [
        {
            title: "Hard Copy Receive",
            dataIndex: "hard_copy_receive",
            render: (text, record) => (
                <div>
                    <CopyOutlined className={classes["copy-icon"]} />
                </div>
            ),
            width: "10%",
        },
        {
            title: "S. No.",
            dataIndex: "s_no",
            width: "8%",
        },
        {
            title: "Bill Date",
            dataIndex: "bill_date",
            width: "11%",
        },
        {
            title: "Last Date Of Entry",
            dataIndex: "last_date_of_entry",
            width: "12%",
        },
        {
            title: "Bill No. Prefix",
            dataIndex: "bill_no_prefix",
            width: "12%",
        },
        {
            title: "Bill Amount",
            dataIndex: "bill_amount",
            width: "10%",
        },
        {
            title: "GST",
            dataIndex: "gst",
            width: "10%",
        },
        {
            title: "Total",
            dataIndex: "total",
            width: "12%",
        },
        {
            title: "HSD Deduction Amount",
            dataIndex: "hsd_deduction_amount",
            width: "15%",
        },
        {
            title: "TDS Deduction",
            dataIndex: "tds_deduction",
            width: "12%",
        },
        {
            title: "Other Deduction",
            dataIndex: "other_deduction",
            width: "12%",
        },
        {
            title: "Billed Payable",
            dataIndex: "bill_balance",
            width: "12%",
        },
        {
            title: "With Held Gst",
            dataIndex: "with_held_gst",
            render: (text, record) => (
                <div>
                    <Checkbox checked={checkWithHeldGSTTrueToRecordCheckbox(record?.key)} onChange={(e) => selectRowsWithHeldGST(record?.key, e.target.checked)} /> &nbsp; &#x20b9; {text}
                </div>
            ),
            width: "20%",
        },
    ];

    const selectRowsWithHeldGST = (recordId, checked = false) => {
        if (checked) {
            setWithHeldGSTRecordIdsTransport([...withHeldGSTRecordIdsTransport, recordId])
        } else {
            const currentwithHeldGSTArr = [...withHeldGSTRecordIdsTransport]
            const recordIndex = currentwithHeldGSTArr.indexOf(recordId)
            currentwithHeldGSTArr.splice(recordIndex, 1)
            setWithHeldGSTRecordIdsTransport(currentwithHeldGSTArr)
        }
    }

    const checkWithHeldGSTTrueToRecordCheckbox = (recordId) => {
        return withHeldGSTRecordIdsTransport?.includes(recordId)
    }

    const addWidgetInfoToExport = (workBook) => {
        const workSheetBilledDetailAllBills = workBook.addWorksheet("All Bills")
        const workSheetBilledDetailHardCopyReceived = workBook.addWorksheet("Hard Copy Received")
        const workSheetBilledDetailPendingHardCopy = workBook.addWorksheet("Pending Hard Copy")
        const workSheetBilledDetailWithHeldGST = workBook.addWorksheet("With Held GST")

        // prepare sheet columns / headers
        workSheetBilledDetailAllBills.columns = workSheetBilledDetailHardCopyReceived.columns = workSheetBilledDetailPendingHardCopy.columns = workSheetBilledDetailWithHeldGST.columns = [
            { header: 'Bill Amount', key: "first_value", width: 25 },
            { header: props?.widgetContentInfoAllBills?.data?.attributes?.sumBillAmount ?? 0, key: "second_value", width: 20 },
        ]

        // prepare sheet rows to display
        workSheetBilledDetailAllBills.addRow(
            { first_value: "GST", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumGST ?? 0 ?? '-' }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "GST", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumGST ?? 0 ?? '-' }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "GST", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumGST ?? 0 ?? '-' }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "GST", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumGST ?? 0 ?? '-' }
        )



        workSheetBilledDetailAllBills.addRow(
            { first_value: "Total", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumTotal ?? 0 ?? '-' }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Total", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumTotal ?? 0 ?? '-' }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Total", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumTotal ?? 0 ?? '-' }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Total", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumTotal ?? 0 ?? '-' }
        )




        workSheetBilledDetailAllBills.addRow({
            first_value: "Hsd Deduction", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumHSDAmount ?? 0 ?? '-'
        })
        workSheetBilledDetailHardCopyReceived.addRow({
            first_value: "Hsd Deduction", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumHSDAmount ?? 0 ?? '-'
        })
        workSheetBilledDetailPendingHardCopy.addRow({
            first_value: "Hsd Deduction", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumHSDAmount ?? 0 ?? '-'
        })
        workSheetBilledDetailWithHeldGST.addRow({
            first_value: "Hsd Deduction", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumHSDAmount ?? 0 ?? '-'
        })





        workSheetBilledDetailAllBills.addRow(
            { first_value: "Tds Deduction", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumTdsAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Tds Deduction", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumTdsAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Tds Deduction", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumTdsAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Tds Deduction", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumTdsAmount ?? 0 ?? 0 }
        )





        workSheetBilledDetailAllBills.addRow(
            { first_value: "Other Deduction", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumOtherDeductionAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Other Deduction", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumOtherDeductionAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Other Deduction", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumOtherDeductionAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Other Deduction", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumOtherDeductionAmount ?? 0 ?? 0 }
        )





        workSheetBilledDetailAllBills.addRow(
            { first_value: "Billed Payable", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumTotalPayableAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Billed Payable", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumTotalPayableAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Billed Payable", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumTotalPayableAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Billed Payable", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumTotalPayableAmount ?? 0 ?? 0 }
        )




        workSheetBilledDetailAllBills.addRow(
            { first_value: "With Held GST", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumWithHeldAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "With Held GST", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumWithHeldAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "With Held GST", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumWithHeldAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "With Held GST", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumWithHeldAmount ?? 0 ?? 0 }
        )



        workSheetBilledDetailAllBills.addRow(
            { first_value: "Already Paid", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumAlreadyPaidAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Already Paid", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumAlreadyPaidAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Already Paid", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumAlreadyPaidAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Already Paid", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumAlreadyPaidAmount ?? 0 ?? 0 }
        )




        workSheetBilledDetailAllBills.addRow(
            { first_value: "Billed Balance", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.totalBilledBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Billed Balance", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.totalBilledBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Billed Balance", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.totalBilledBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Billed Balance", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.totalBilledBalance ?? 0 ?? 0 }
        )




        workSheetBilledDetailAllBills.addRow(
            { first_value: "Unbilled Balance", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.totalUnBilledBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Unbilled Balance", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.totalUnBilledBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Unbilled Balance", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.totalUnBilledBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Unbilled Balance", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.totalUnBilledBalance ?? 0 ?? 0 }
        )




        workSheetBilledDetailAllBills.addRow(
            { first_value: "Eff Balance", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.totalEffectiveBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Eff Balance", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.totalEffectiveBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Eff Balance", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.totalEffectiveBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Eff Balance", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.totalEffectiveBalance ?? 0 ?? 0 }
        )

    }

    const exportPaymentSummaryTransport = async () => {

        setIsExportButtonDisabled(true)

        const exportResponse = await AppHelper.getBilledRecordsBilledDetails(true, 1, exportPageSize, 'transporting', props?.currentSelectedAgencyId)
        if (exportResponse?.error) {
            setIsExportButtonDisabled(false)
            notification.error({
                message: "Error",
                description: exportResponse?.error?.message,
                duration: 3
            })
        } else {
            // export xlsx code goes here
            const workBook = new exceljs.Workbook()
            const workSheetPendingBills = workBook.addWorksheet("Transporter Billed", {
                views: [{ ySplit: 1, state: 'frozen' }]
            })
            workSheetPendingBills.columns = [
                { header: "S. No.", key: "s_no", width: 10 },
                { header: "Bill Date", key: "bill_date", width: 15 },
                { header: "Last Date Of Entry", key: "last_date_of_entry", width: 25 },
                { header: "Bill No. Prefix", key: "bill_no_prefix", width: 20 },
                { header: "Bill Amount", key: "bill_amount", width: 15 },
                { header: "GST", key: "gst", width: 10 },
                { header: "Total", key: "total", width: 12 },
                { header: "HSD Deduction Amount", key: "hsd_deduction_amount", width: 25 },
                { header: "TDS Deduction", key: "tds_deduction", width: 20 },
                { header: "Other Deduction", key: "other_deduction", width: 20 },
                { header: "Billed Balance", key: "bill_balance", width: 15 },
                { header: "With Held GST", key: "with_held_gst", width: 15 }
            ]

            for (let i = 0; i < exportResponse?.data?.attributes?.results?.length; i++) {

                const element = exportResponse?.data?.attributes?.results[i];

                let billedBalance = parseFloat(element?.total_payable_amount)

                // subtract gst from bill balance if withheld gst is appied to bill record
                const withHeldGSTAmountRecordExists = withHeldGSTRecordIdsTransport?.includes(element?.id)

                if (withHeldGSTAmountRecordExists)
                    billedBalance = billedBalance - parseFloat(element?.gst)

                let exportData = {}
                exportData.key = element?.id
                exportData.s_no = i + 1
                exportData.hard_copy_receive = ""
                exportData.bill_date = dayjs(element?.date_of_billing).format(dateFormat)
                exportData.bill_no_prefix = element?.billing_prefix
                exportData.last_date_of_entry = dayjs(element?.date_of_billing).format(dateFormat)
                exportData.bill_amount = element?.work_amount
                exportData.gst = element?.gst ?? 0
                exportData.total = (parseFloat(element?.work_amount) + parseFloat(element?.gst))
                exportData.hsd_deduction_amount = parseFloat(element?.hsd_amount)
                exportData.tds_deduction = parseFloat(element?.less_tds)
                exportData.other_deduction = parseFloat(element?.other_deduction)
                exportData.bill_balance = parseFloat(billedBalance)
                exportData.with_held_gst = element?.with_held_gst_payment ? 'Yes' : 'No'
                workSheetPendingBills.addRow(exportData)
            }

            // preapare sheet for global counts
            const workSheetBilledDetailUnBilled = workBook.addWorksheet("Transporter Unbilled")
            workSheetBilledDetailUnBilled.columns = [
                { header: "S. no.", key: "s_no", width: 10 },
                { header: "Bill Amount", key: "bill_amount", width: 25 },
                { header: "GST", key: "gst", width: 25 },
                { header: "Total", key: "total", width: 25 },
                { header: "HSD Deduction Amount", key: "hsd_deduction_amount", width: 25 },
                { header: "TDS Deduction", key: "tds_deduction", width: 25 },
                { header: "Other Deduction", key: "other_deduction", width: 25 },
                { header: "Unbilled Payable", key: "unbilled_payable", width: 25 },
            ]

            const exportResponseUnBilled = await AppHelper.getUnBilledRecordsBilledDetails('transporting', props?.currentSelectedAgencyId)
            if (exportResponseUnBilled?.data?.attributes?.totalBilledAmount > 0 || exportResponseUnBilled?.data?.attributes?.hsdDeductionAmount > 0) {
                workSheetBilledDetailUnBilled.addRow({
                    key: 1,
                    bill_amount: exportResponseUnBilled?.data?.attributes?.totalBilledAmount.toLocaleString('en-in') ?? 0,
                    s_no: 1,
                    gst: '-',
                    total: exportResponseUnBilled?.data?.attributes?.totalBilledAmount.toLocaleString('en-in') ?? 0,
                    hsd_deduction_amount: exportResponseUnBilled?.data?.attributes?.hsdDeductionAmount.toLocaleString('en-in') ?? 0,
                    tds_deduction: '-',
                    other_deduction: '-',
                    unbilled_payable: (parseFloat(exportResponseUnBilled?.data?.attributes?.totalBilledAmount) - parseFloat(exportResponseUnBilled?.data?.attributes?.hsdDeductionAmount)).toLocaleString('en-in')
                })
            }

            addWidgetInfoToExport(workBook)

            // set header to export as excel file
            try {
                const buffer = await workBook.xlsx.writeBuffer()
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                const excelExtension = constantsArr.EXPORT_EXCEL_EXTENSION
                const blob = new Blob([buffer], { type: fileType })
                FileSaver.saveAs(blob, `payment_billed_detail_transporter_export_data_${dayjs().format(dateFormat)}.${excelExtension}`)
                // Conclude function now
                notification.success({
                    message: "Payment billed detail transporter data exported successfully",
                    description: '',
                    duration: 3
                })
            } catch (error) {
                console.log(error, 'error');
                notification.error({
                    message: "Error",
                    description: exportResponse?.error?.message,
                    duration: 3
                })
            }
            setIsExportButtonDisabled(false)
        }
    }

    const getRecordIdsInBillToAutoSelectForWithHeldGSTHardCopyRecievedTransport = (loadings) => {
        const recordIdsForWithHeldGSTSelected = loadings?.length > 0 ? loadings?.filter(item => item?.with_held_gst_payment === true).map(item => item?.id) : []
        setWithHeldGSTRecordIdsTransport(recordIdsForWithHeldGSTSelected)

        const recordIdsForHardCopyReceivedSelected = loadings?.length > 0 ? loadings?.filter(item => item?.hard_copy_received_payment === true).map(item => item?.id) : []
        setSelectedRowKeys(recordIdsForHardCopyReceivedSelected)
    }

    /**
     * Export and save methods
     */

    const saveTransportBill = () => {
        saveHardCopyReceivedTransport()
        saveHeldWithGSTTransport()
        notification.success({
            message: 'Success',
            description: 'Selected records status has been updated successfully',
            duration: 3
        })
    }

    const getBilledRecordsTransportDetails = (pagination, currentPage, pageSize, agencyType, agencyId, currentSelectedWidgetTabKey) => {
        setTableData([])
        AppHelper.getBilledRecordsBilledDetails(pagination, currentPage, pageSize, agencyType, agencyId, currentSelectedWidgetTabKey).then(response => {
            const getTableDataContents = getTableData(response)
            setTableData(getTableDataContents)
        }).catch(err => {
            console.log(err)
            notification.error({
                message: "Error",
                description: err?.error?.message,
                duration: 3
            })
            return false;
        })
    }
    const saveHeldWithGSTTransport = () => {
        AppHelper.saveWithHeldGST(withHeldGSTRecordIdsTransport, 'transporting', props?.currentSelectedAgencyId).then(response => {
            getBilledRecordsTransportDetails(true, 1, exportPageSize, 'transporting', props?.currentSelectedAgencyId, props?.widgetTabCheckedKey)
            props?.fetchWidgetContentInfoWithHeldGST()
        }).catch(err => {
            notification.error({
                message: 'Error',
                description: err?.error?.message,
                duration: 3
            })
            return false;
        })
    }

    const saveHardCopyReceivedTransport = () => {
        AppHelper.saveHardCopyReceivedTransport(selectedRowKeys, 'transporting', props?.currentSelectedAgencyId).then(response => {
            props?.fetchWidgetContentInfoAllBills()
            props?.fetchWidgetContentInfoHardCopyReceived()
            props?.fetchWidgetContentInfoPendingHardCopy()
        }).catch(err => {
            notification.error({
                message: 'Error',
                description: err?.error?.message,
                duration: 3
            })
        })
    }

    const getUnBilledRecordsTransport = (agencyType, agencyId) => {
        AppHelper.getUnBilledRecordsBilledDetails(agencyType, agencyId).then(response => {
            const tableContent = getTableDataUnBilled(response)
            setTableUnBilledData(tableContent)
        }).catch(err => {
            console.log(err)
            notification.error({
                message: "Error",
                description: err?.error?.message,
                duration: 3
            })
        })
    }

    const onSelectChangeCheckboxes = (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys);

    const rowSelectionTransport = {
        selectedRowKeys,
        onChange: onSelectChangeCheckboxes,
        fixed: "left",
        preserveSelectedRowKeys: true
    };

    const getTableDataUnBilled = (records) => {

        let data = []

        if (records?.data?.attributes?.totalBilledAmount > 0 || records?.data?.attributes?.hsdDeductionAmount > 0) {
            data = [{
                key: 1,
                bill_amount: records?.data?.attributes?.totalBilledAmount.toLocaleString('en-in') ?? 0,
                s_no: 1,
                gst: '-',
                total: records?.data?.attributes?.totalBilledAmount.toLocaleString('en-in') ?? 0,
                hsd_deduction_amount: records?.data?.attributes?.hsdDeductionAmount.toLocaleString('en-in') ?? 0,
                tds_deduction: '-',
                other_deduction: '-',
                unbilled_payable: (parseFloat(records?.data?.attributes?.totalBilledAmount) - parseFloat(records?.data?.attributes?.hsdDeductionAmount)).toLocaleString('en-in')
            }];
        }

        return data
    };

    const getTableData = (records) => {

        const data = [];

        for (let i = 0; i < records?.data?.attributes?.results?.length; i++) {

            const element = records?.data?.attributes?.results[i];

            let billedBalance

            if (element?.with_held_gst_payment) {
                billedBalance = parseFloat(element?.work_amount)
            } else {
                billedBalance = parseFloat(element?.work_amount) + parseFloat(element?.gst)
            }

            billedBalance = (parseFloat(billedBalance) - ((parseFloat(element?.hsd_amount) + parseFloat(element?.less_tds) + parseFloat(element?.other_deduction))))

            data.push({
                key: element?.id,
                s_no: i + 1,
                hard_copy_receive: "",
                bill_date: dayjs(element?.date_of_billing).format(dateFormat),
                bill_no_prefix: element?.billing_prefix,
                last_date_of_entry: dayjs(element?.lastDateOfEntry).format(dateFormat),
                bill_amount: element?.work_amount.toLocaleString('en-in'),
                gst: element?.gst.toLocaleString('en-in') ?? 0,
                total: (parseFloat(element?.work_amount) + parseFloat(element?.gst)).toLocaleString('en-in'),
                hsd_deduction_amount: parseFloat(element?.hsd_amount).toLocaleString('en-in'),
                tds_deduction: parseFloat(element?.less_tds).toLocaleString('en-in'),
                other_deduction: parseFloat(element?.other_deduction).toLocaleString('en-in'),
                bill_balance: parseFloat(billedBalance).toLocaleString('en-in'),
                with_held_gst: parseFloat(element?.gst).toLocaleString('en-in'),
            });
        }

        return data
    };

    useEffect(() => {
        if (props?.currentSelectedAgencyId) {
            getUnBilledRecordsTransport('transporting', props?.currentSelectedAgencyId)
            getRecordIdsInBillToAutoSelectForWithHeldGSTHardCopyRecievedTransport(props?.billedRecordData?.data?.attributes?.results)
            const getTableDataContents = getTableData(props?.billedRecordData ?? [])
            setTableData(getTableDataContents)

        }
    }, [props?.currentSelectedAgencyId])

    return (
        <>
            {
                props?.currentSelectedAgencyId ?
                    props?.transporterActiveTab === "billed" ? (
                        <>
                            <div className={classes["loading-bar"]}>
                                <Table
                                    rowSelection={rowSelectionTransport}
                                    columns={columnsBilledTransport}
                                    dataSource={tableData}
                                    rowKey={(record) => record?.key}
                                    bordered={true}
                                    scroll={{ x: 1800 }}
                                    pagination={{
                                        position: ['bottomCenter']
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={classes["loading-bar"]}>
                                <Table
                                    columns={columnsUnBilled}
                                    dataSource={unbilledTableData}
                                    bordered={true}
                                    scroll={{ x: 1500 }}
                                    pagination={false}
                                />
                            </div>
                        </>
                    )
                    :
                    ""
            }
            <Grid className={classes["export-submit-button"]}>
                <Button
                    variant="outlined"
                    className={`${classes["filter-new-entry"]} ${classes["export-button"]} ${isExportButtonDisabled ? classes['button-disabled'] : ''}`}
                    onClick={exportPaymentSummaryTransport}
                    disabled={isExportButtonDisabled}>
                    Export
                </Button>
                <Button variant="contained" className={classes["submit-button"]} onClick={saveTransportBill}>
                    Save
                </Button>
            </Grid>
        </>
    );
};
