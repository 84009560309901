import React from "react";
import { Button } from "@mui/material";
import classes from "./BreadCrumbClientBilling.module.scss";
import pendingActiveIcon from "../../../../assets/images/BillsIcons/pending-bill-white.svg";
import pendingInActiveIcon from "../../../../assets/images/BillsIcons/Pending.svg";
import generateBillActiveIcon from "../../../../assets/images/BillsIcons/generate-bill-white.svg";
import generateBillInActiveIcon from "../../../../assets/images/BillsIcons/Generate.svg";
import FinalCalculationActiveIcon from "../../../../assets/images/BillsIcons/Final-white.svg";
import FinalCalculationInActiveIcon from "../../../../assets/images/BillsIcons/Final-calculation.svg";
import { useNavigate } from "react-router-dom";
import AppHelper from "../../../../helpers/AppHelper";

export const BreadCrumbClientBilling = ({ activeButton, page, billId }) => {
    const navigate = useNavigate();
    const selected_bill_date = AppHelper.decryptText(sessionStorage.getItem('selected_bill_date'))
    const redirectToPendingBillScreen = () => {
        if (page === "clientBill") {
            navigate("/bills/client-bill/pending-client-bill");
            return false;
        } else {
            navigate(`/bills/client-bill/edit/${billId}`);
            return false;
        }
    };

    const redirectToGenerateBillScreen = () => {
        if (page === "clientBill") {
            navigate("/bills/client-bill/generate");
            return false;
        } else {
            navigate(`/bills/client-bill/edit-generate/${billId}`);
            return false;
        }
    };

    const redirectToFinalCalculationScreen = () => {
        if (page === "clientBill") {
            navigate("/bills/client-bill/final-calculation");
            return false;
        } else {
            navigate(`/bills/client-bill/edit-final-calculation/${billId}`);
            return false;
        }
    };
    return (
        <>
            <ul className={classes["breadcrumb_buttons"]}>
                <li>
                    <Button
                        type="primary"
                        className={`${classes["pending_bills_icon"]} ${activeButton === "pending_client_bills" || activeButton === "edit_pending_client_bills"
                            ? classes["button_active"]
                            : classes["button_inactive"]
                            }`}
                        onClick={
                            activeButton === "pending_client_bills" || activeButton === "edit_pending_client_bills" || selected_bill_date === ""
                                ? undefined
                                : redirectToPendingBillScreen
                        }
                    >
                        <img
                            src={
                                activeButton === "pending_client_bills" || activeButton === "edit_pending_client_bills"
                                    ? pendingActiveIcon
                                    : pendingInActiveIcon
                            }
                            alt="Pending Bills Screen"
                        />
                        Pending
                    </Button>
                </li>
                <li>
                    <Button
                        type="primary"
                        className={`${classes["generate_bills_icon"]} ${classes["step_button"]
                            } ${activeButton === "pending_client_bills_generate" || activeButton === "edit_pending_client_bills_generate"
                                ? classes["button_active"]
                                : classes["button_inactive"]
                            }`}
                        onClick={
                            activeButton === "pending_client_bills_generate" || activeButton === "edit_pending_client_bills_generate" || selected_bill_date === ""
                                ? undefined
                                : redirectToGenerateBillScreen
                        }
                    >
                        <img
                            src={
                                activeButton === "pending_client_bills_generate" || activeButton === "edit_pending_client_bills_generate"
                                    ? generateBillActiveIcon
                                    : generateBillInActiveIcon
                            }
                            alt="Generate Bills Screen"
                        />
                        Generate
                    </Button>
                </li>
                <li>
                    <Button
                        type="primary"
                        className={`${classes["final_calculation_bills_icon"]} ${classes["step_button"]
                            } ${activeButton === "client_bill_final_calculation" || activeButton === "edit_client_bill_final_calculation"
                                ? classes["button_active"]
                                : classes["button_inactive"]
                            }`}
                        onClick={
                            activeButton === "client_bill_final_calculation" || activeButton === "edit_client_bill_final_calculation" || selected_bill_date === ""
                                ? undefined
                                : redirectToFinalCalculationScreen
                        }
                    >
                        <img
                            src={
                                activeButton === "client_bill_final_calculation" || activeButton === "edit_client_bill_final_calculation"
                                    ? FinalCalculationActiveIcon
                                    : FinalCalculationInActiveIcon
                            }
                            alt="Final Calculation Screen"
                        />
                        Final Calculation
                    </Button>
                </li>
            </ul>
        </>
    );
};
